import extendNode from '../extendNode.mjs'
import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaBdBase.mjs'

export default () => {
    const AlgCatNode = useAlgCatNode()

    return extendNode(AlgCatNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(BDBASE|CAT)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'BDBASE'
        },

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}
            return '2' === bdType
        },

        async onCreate(map, node) {
            node.data = {...node.data, algType: 'BASE'}
            await AlgCatNode.onCreate.call(this, map, node)
        },
    })
}
