import http from '@/biz/http.mjs'
import {replaceWithTree} from '../../scripts/map.mjs'

export const canRefreshProduct = () => () => {
    return function (node) {
        const {bizNodeType, pkid} = node.data

        if (! pkid) {
            this.logger.error('只有制品才能重载', [node])
            return false
        }

        const bn = this.BizNode[bizNodeType]

        if (! bn.readMap) {
            this.logger.error(`${bn.name}不支持重载`, [node])
            return false
        }

        return bn.canWriteNode(this, node)
    }
}

export const refreshProduct = () => {

    return () => function (nodes) {
        return Promise.allSettled(
            [...nodes].map(
                async node => {
                    try {
                        const {bizNodeType, pkid} = node.data

                        const [project, {data, root}] = await Promise.all([
                            http.get(`/prjs/${this.data.prjId}/info`),
                            this.BizNode[bizNodeType].readMap({pkid}),
                        ])

                        this.data = {...data, project}
                        replaceWithTree(this, this.root, root)
                        this.logger.info('重载模型制品成功', [node])
                        this.selectNodes([node])
                    }
                    catch (err) {
                        this.logger.error(
                            `重载模型制品失败: ${err.message}`,
                            [node]
                        )

                        err.nodes = [node]
                        throw err
                    }
                }
            )
        )
    }
}
