import extendNode from '../extendNode.mjs'
import uuid from '@/script/uuid.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import usePropertiesModal from '../hooks/usePropertiesModal.jsx'
import IconFile from '../icons/IconFile.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import IconStar from '../icons/IconStar.jsx'
import meta from './metaFj.mjs'
import Model from './ModelFj.mjs'
import ModalProperties from './ModalPropertiesFj.jsx'
import PropertiesPane from './PropertiesPaneFj.jsx'

export default () => {
    const ComponentNode = useComponentNode()
    const openPropertiesModal = usePropertiesModal(ModalProperties)

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(DT|FC|LSI)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(DOC)$/.test(type)
        },

        getIcons(map, node) {
            const icons = []

            const {
                dtOprCode,
                fjFcCode,
                fjLsiCode,
                fjTypeCode,
                fmFjDtList = [],
            } = node.data

            if ('DT' === fjTypeCode) {
                if ('C' === dtOprCode) {
                    icons.push(
                        <IconLetters
                            fill={Color.GREEN_YELLOW}
                            letters="C"
                            textColor="#666"
                        />
                    )
                }
                else if ('D' === dtOprCode) {
                    icons.push(
                        <IconLetters
                            fill={Color.GREEN_YELLOW}
                            letters="D"
                            textColor="#666"
                        />
                    )
                }
                else if ('R' === dtOprCode) {
                    icons.push(
                        <IconLetters
                            fill={Color.GREEN_YELLOW}
                            letters="R"
                            textColor="#666"
                        />
                    )
                }
                else if ('U' === dtOprCode) {
                    icons.push(
                        <IconLetters
                            fill={Color.GREEN_YELLOW}
                            letters="U"
                            textColor="#666"
                        />
                    )
                }

                if (0 < fmFjDtList.length) {
                    icons.push(<IconStar fill={Color.RED} />)
                }
                else {
                    icons.push(<IconStar fill={Color.GREY} />)
                }
            }
            else if ('FC' === fjTypeCode) {
                icons.push(
                    <IconLetters
                        fill={Color.GREEN_YELLOW}
                        letters="A"
                        textColor="#666"
                    />
                )

                if (fjFcCode) {
                    icons.push(<IconStar fill={Color.RED} />)
                }
                else {
                    icons.push(<IconStar fill={Color.GREY} />)
                }
            }
            else if ('FILE' === fjTypeCode) {
                icons.push(
                    <IconFile
                        fill={Color.GREEN_YELLOW}
                        theme="dark"
                    />
                )
            }
            else if ('LSI' === fjTypeCode) {
                icons.push(
                    <IconLetters
                        fill={Color.GREEN_YELLOW}
                        letters="LI"
                        textColor="#666"
                    />
                )

                if (fjLsiCode) {
                    icons.push(<IconStar fill={Color.RED} />)
                }
                else {
                    icons.push(<IconStar fill={Color.GREY} />)
                }
            }
            else if ('NEW' === fjTypeCode) {
                icons.push(
                    <IconLetters
                        letters="N"
                        textColor={Color.GREEN}
                    />
                )
            }
            else if ('VERIFY' === fjTypeCode) {
                icons.push(
                    <IconLetters
                        fill="#f88634"
                        letters="!"
                        textColor="#fff"
                    />
                )
            }

            return icons
        },

        getRev(map, node) {
            return null
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.GREEN_YELLOW,
                }),

                shape: 'Parallelogram',
            }
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['DT'],
                ['LSI'],
                ['FC'],
            ]
        },

        async onCreate(map, node) {
            node.data = {
                ...node.data,
                fjTypeCode: 'KEY',
                txNo: '1',
            }

            await ComponentNode.onCreate.call(this, map, node)
        },

        onDoubleClick(map, node, event) {
            event.preventDefault()
            openPropertiesModal(map, node, Model)
        },

        async onPull(map, node) {
            await ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            if ('FC' !== type) {
                return
            }

            const updates = ({
                DT: () => {
                    const fmFjDtList = []

                    for (const child of map.children(node)) {
                        const {bizNodeType} = child.data

                        if ('DT' === bizNodeType) {
                            const {
                                pkid: dtId,
                                [map.BizNode.DT.textProp]: dtName
                            } = child.data

                            fmFjDtList.push({dtId, dtName})

                            if (/^[CDU]$/.test(node.data.dtOprCode)) {
                                break
                            }
                        }
                    }

                    return {
                        fjFcCode: '',
                        fjLsiCode: '',
                        fjLsSysId: '',
                        fjSsCode: '',
                        fmFjDtList,
                    }
                },

                FC: () => {
                    for (const child of map.children(node)) {
                        const {bizNodeType} = child.data

                        if ('FC' === bizNodeType) {
                            const {
                                fcCode: fjFcCode,
                                ssCode: fjSsCode,
                            } = child.data

                            return {
                                fjFcCode,
                                fjLsiCode: '',
                                fjLsSysId: '',
                                fjSsCode,
                                fmFjDtList: [],
                            }
                        }
                    }
                },

                LSI: () => {
                    for (const child of map.children(node)) {
                        const {bizNodeType} = child.data

                        if ('LSI' === bizNodeType) {
                            const {
                                lsiCode: fjLsiCode,
                                lsId: fjLsSysId,
                            } = child.data

                            return {
                                fjFcCode: '',
                                fjLsiCode,
                                fjLsSysId,
                                fjSsCode: '',
                                fmFjDtList: [],
                            }
                        }
                    }
                },
            }[node.data.fjTypeCode])?.() ?? {}

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType, pkid} = n.data

                if ('FC' === bizNodeType) {
                    const text = map.BizNode.FC.getTextRaw(map, n)

                    Object.assign(updates, {
                        [this.ownerIdProp]: pkid,
                        [this.ownerTextProp]: text,
                    })

                    break
                }
            }

            if (! node.data.pkid) {
                Object.assign(updates, {
                    fjCode: uuid(),
                    pkid: uuid(),
                })
            }

            node.data = {...node.data, ...updates}
            data.fmFjList.push(this.getPushData(map, node))
        },
    })
}
