import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaUirule.mjs'
import Model from './ModelUirule.mjs'
import PropertiesPane from './PropertiesPaneUirule.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchUirule.jsx'
import QueryForm from './QueryFormSelectUirule.jsx'
import Table from './TableSelectUirule.jsx'
import api from './apiUirule.mjs'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        canMountType(map, node, type) {
            return /^(AR|DOC|PIC|URL)$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, query) => {
                const {bizNodeType, uiUserCode} = map.root.data

                if ('UI' === bizNodeType) {
                    return {uiUserCode, ...query}
                }
                else {
                    return query
                }
            }

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getIcons(map, node) {
            const {ruleType} = node.data

            if ('DC' === ruleType) {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.LIGHT_BLUE}
                        letters="C"
                        textColor="#fff"
                    />
                ]
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.LIGHT_BLUE}
                        letters="R"
                        textColor="#fff"
                    />
                ]
            }
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_BLUE,
                shape: 'EllipseRectangle',
            })
        },

        async onCreate(map, node) {
            node.data = {...node.data, ruleType: 'COMMON'}
            await ComponentNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                UI: 'ruleList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },

        _getPushData(map, node, slots) {
            const data = ComponentNode._getPushData.call(
                this, map, node, slots
            )

            const {bizNodeType} = node.data

            const tree = map.BizNode[bizNodeType].exportTree(
                map, node, {excludeGrowed: true}
            )

            const ruleMap = JSON.stringify(tree.children)
            return {...data, ruleMap}
        },
    })
}
