export const canDeleteNode = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canDeleteNode(node) &&
            this.BizNode[bizNodeType].canDeleteNode(this, node)
        )
    }
}
