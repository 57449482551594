import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
import meta from './metaFm.mjs'
import Model from './ModelFm.mjs'
import PropertiesPane from './PropertiesPaneFm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(FM)$/.test(type)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {borderColor: Color.GOLD})
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if (/^([BD]M)$/.test(bizNodeType)) {
                this.castFrom(map, child)
            }

            ModuleNode.onAttached.call(this, map, node, child)
        },

        onPush(map, node, type, data) {
            const key = {
                FA: 'archFmList',
                FM: 'child'
            }[type]

            if (key) {
                data[key].push(
                    this._getPushData(map, node, {child: []})
                )
            }
        },
    })
}
