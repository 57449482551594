import {usePreviewImages} from '@/components/PreviewImages/PreviewImages'
import buildUrl from '@/script/buildUrl.mjs'
import Tree from '@/script/Tree.mjs'

export default () => {
    const previewImages = usePreviewImages()

    return async (map, ui) => {
        const rev = map.BizNode.UI.getRev(map, ui)
        const {pkid} = ui.data
        const treeData = await map.BizNode.UI.readTree({pkid, rev})
        const tree = new Tree(treeData)

        const previewPics = pics => previewImages(
            pics.map(pic => `${import.meta.env.VITE_HTTP_BASE_URL}/files/${pic.data.fileId}`)
        )

        const previewUv = uv => {
            const {pkid: uvId} = uv.data
            const url = buildUrl('/formilyPreview', {isTemplate: false, uvId})
            window.open(url)
        }

        for (const n of tree.children(tree.root)) {
            if ('UI_DEMO' !== n.data.bizNodeType) {
                continue
            }

            const pics = []

            const next = chain => {
                const {bizNodeType} = chain[0].data
                const yieldChildren = map.BizNode[bizNodeType].isComment
                return {yieldChildren}
            }

            for (
                const nn of
                tree.walkDown(n, {excludeTarget: true, next})
            ) {
                const {bizNodeType} = nn.data

                if ('PIC' === bizNodeType) {
                    pics.push(nn)
                }
                else if ('UV' === bizNodeType) {
                    if (0 === pics.length) {
                        previewUv(nn)
                        break
                    }
                }
            }

            if (0 < pics.length) {
                previewPics(pics)
            }
        }
    }
}
