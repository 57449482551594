import http from '@/biz/http.mjs'

export default {

    readList: (args) => http.get(
        '/dmbds/algs',

        {
            algTypes: ['BASE', 'DEVELOP'],
            ...args
        }
    ),
}
