const insertNode = async (map, nodes, insert) => {
    const nodesToSelect = new Set

    await Promise.all(
        [...nodes].map(async n => {
            const _n = map.nodeProxy(n)
            const t = _n.defaultChildType()

            if (! t) {
                return null
            }

            const child = map.createNode(t)
            insert(n, child)
            await map.BizNode[t].onCreate(map, child)

            if (! child.isDeleted) {
                map.logger.info('已插入子节点', [child])
                nodesToSelect.add(child)
            }
        })
    )

    map.selectNodes(nodesToSelect)
}

export const canInsertChild = () => defaultActions => {
    return function (node) {
        const _n = this.nodeProxy(node)

        return (
            defaultActions.canInsertChild(node) &&
            _n.canWriteTree()
        )
    }
}

export const appendChild = () => () => {
    return function (nodes) {
        return insertNode(this, nodes, this.appendChild.bind(this))
    }
}

export const prependChild = () => () => {
    return function (nodes) {
        return insertNode(this, nodes, this.prependChild.bind(this))
    }
}
