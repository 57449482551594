export const canCopyNodeStyle = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canCopyNodeStyle(node) &&
            this.BizNode[bizNodeType].canCopyStyle(this, node)
        )
    }
}
