import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Db from '../components/PropertiesControllerDb.jsx'
import Dm from '../components/PropertiesControllerDm.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelDt.mjs'

const PropertiesPaneDt = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Db />
                <Dm />
                <Text prop="dtUserCode" />
                <Text prop="dtName" />
                <Text prop="dtAlias" />
                <LongText prop="dtDesc" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse modes={[
                'Effect',
                'FkDepend',
                'FkEffect',
                'Reference',
            ]} />
        }

        {...props}
    />
)

export default PropertiesPaneDt
