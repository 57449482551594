import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaCatDoc.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|DOC|MARK|PIC|URL)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'PIC'
        },
    })
}
