import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'dtUserCode',
    detailUrl: '/DtDetail',
    detailIdProp: 'dtId',
    mapProp: 'dtMap',
    name: '数据表',
    textProp: 'dtName',
    type: 'DT',
}
