import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ButtonMap from '../components/PropertiesPaneButtonMap.jsx'
import Model from './ModelFm.mjs'

const PropertiesPaneFm = (props) => (
    <ComponentPropertiesPane
        buttons={
            <>
                <ButtonMap />
                <ButtonExportModel type="fm" />
                <ButtonExportModel format="excel" type="fm" />
            </>
        }

        controllers={
            <>
                <Text prop="fmUserCode" />
                <Text prop="fmName" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneFm
