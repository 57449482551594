import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'

const PropertiesPaneFa = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                <ButtonExportModel type="fm" />
                <ButtonExportModel format="excel" type="fm" />
                <ButtonImportModules type="fm" />
            </>
        }

        {...props}
    />
)

export default PropertiesPaneFa
