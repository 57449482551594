import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Check from '../../SidePanel/NodePanel/PropertiesControllerCheck.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Number from '../../SidePanel/NodePanel/PropertiesControllerNumber.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonMakeFk from './PropertiesPaneButtonMakeFk.jsx'
import DataType from './PropertiesControllerDataType.jsx'
import DefVal from './PropertiesControllerDefVal.jsx'
import DfPrecision from './PropertiesControllerDfPrecision.jsx'
import Dgf from './PropertiesControllerDgf.jsx'
import Model from './ModelDf.mjs'

const PropertiesPaneDf = (props) => (
    <ComponentPropertiesPane
        buttons={<ButtonMakeFk />}

        controllers={
            <>
                <Check prop="isCdgDf" />
                <Dgf />
                <Text prop="dfUserCode" />
                <Text prop="dfName" />
                <Text prop="dfAlias" />
                <DataType />

                <Number
                    int
                    min={1}
                    prop="dfLen"
                />

                <DfPrecision />
                <Check prop="isPk" />
                <Check prop="isBk" />
                <Check prop="isNull" />
                <DefVal />
                <LongText prop="dfDesc" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneDf
