import {Form} from 'antd'
import TextArea from '@/components/Form/TextArea.jsx'
import Checkbox from '@/components/Form/Checkbox.jsx'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'
import apiMap from '../../../apis/apiMap.mjs'

export const canPublishMap = () => () => {
    return function () {
        if (! /^DRAFT|REVISE$/.test(this.data.mapStsCode)) {
            this.logger.error('只有草稿/修订状态的地图才能发布')
            return false
        }

        if (! this.permission.has('write')) {
            this.logger.error('没有发布权限')
            return false
        }

        return true
    }
}

const ModalPublish = ({onOk, ...props}) => {
    const [form] = Form.useForm()

    const handleOk = () => {
        form.submit()
    }

    const cssForm = {
        '.ant-form-item': {
            marginBottom: 0,
        }
    }

    return (
        <Modal
            title="发布地图"
            width={400}
            {...props}
            onOk={handleOk}
            onFinish={onOk}
            css={cssForm}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onOk}
            >
                <Form.Item name="memo">
                    <TextArea
                        autoSize={false}
                        rows={5}
                        placeholder="备注"
                    />
                </Form.Item>

                <Form.Item name="upgradeDp">
                    <Checkbox>升级制品</Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export const publishMap = () => {
    const openModal = useOpenModal()

    return () => async function () {
        const userInputs = await openModal(<ModalPublish />)

        if (! userInputs) {
            return
        }

        return this.runAction(
            async () => {
                const {pkid} = this.data
                const updates = await apiMap.publish({pkid, ...userInputs})
                this.data = {...this.data, ...updates}
            },

            {
                action: '发布地图',
                success: {show: true},
            },
        )
    }
}
