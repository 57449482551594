import {useSWRConfig} from 'swr'

export const canSaveMap = () => () => {
    return function () {
        const {bizNodeType} = this.root.data
        return 'function' === typeof this.BizNode[bizNodeType].updateMap
    }
}

export const saveMap = () => {
    const {mutate} = useSWRConfig()

    return () => async function () {
        const {bizNodeType, pkid} = this.root.data
        await this.BizNode[bizNodeType].updateMap(this, this.root)
        mutate(`/maps/${pkid}`)
    }
}
