import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaPhys_.mjs'
import QueryForm from '../PHYS/QueryFormSelectPhys.jsx'
import Table from '../PHYS/TableSelectPhys.jsx'
import api from './apiPhys_.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,

        async chooseProduct(map, node) {
            const getQuery = (map, node, query) => {
                const {prjId} = map.data
                return {prjId, ...query}
            }

            const items = await this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'PHYS'},
            }))
        },
    })
}
