import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const permission = map.usePermission()

    if (! permission.has('write')) {
        return null
    }

    const {canMakeProduct, makeProduct} = map.commands

    const next = (chain) => {
        const {bizNodeType} = chain[0].data
        const {isProduct} = map.BizNode[bizNodeType]
        return {yieldChildren: ! isProduct}
    }

    const iterNode = map.walkDown(map.root, {excludeTarget: true, next})
    const nodes = canMakeProduct(iterNode)

    if (0 === nodes.length) {
        return null
    }

    return {
        key: 'make-all-products',
        label: '生成所有制品',
        onClick: () => makeProduct(nodes),
    }
}
