import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaSvrsummary.mjs'
import PropertiesPane from './PropertiesPaneSvrsummary.jsx'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        PropertiesPane,

        defaultChildType(map, node) {
            return 'AR'
        },

        getIcons(map, node) {
            const {storyType} = node.data

            if ('SYS' === storyType) {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.GREEN}
                        letters="S"
                        textColor="#000"
                    />
                ]
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.LIGHT_YELLOW}
                        letters="S"
                        textColor="#000"
                    />
                ]
            }
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        onPush(map, node, type, data) {
            const key = {
                SVR: 'algList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(
                map,
                node,
                {fcCode: '', sfCodes: []}
            )

            data[key].push(pushData)
        },
    })
}
