import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootPrj.jsx'
import meta from './metaCatRootPrj.mjs'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^([BFU]A|CAT_(DB|DOC))$/.test(type)
        },
    })
}
