import extendNode from '../extendNode.mjs'
import useDesCatNode from '../_DES_CAT/useDesCatNode.jsx'
import meta from './metaDesOth.mjs'

export default () => {
    const DesCatNode = useDesCatNode()

    return extendNode(DesCatNode, {
        ...meta,
    })
}
