import extendNode from '../extendNode.mjs'
import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaUiCalg.mjs'

export default () => {
    const AlgCatNode = useAlgCatNode()

    return extendNode(AlgCatNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|UIALG)$/.test(type)
        },
    })
}
