import {Col, Row} from 'antd'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import ProgType from './ProgType.mjs'

const ModalPropertiesIprog = () => (
    <Row gutter={[16, 16]}>
        <Col span="6">
            <LongText prop="progTitle" />
        </Col>

        <Col span="6">
            <Select
                options={ProgType}
                prop="progTypeCode"
            />
        </Col>

        <Col span="24">
            <LongText prop="progDesc" />
        </Col>

        <Col span="24">
            <LongText prop="memo" />
        </Col>
    </Row>
)

export default ModalPropertiesIprog
