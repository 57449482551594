export const canUpgradeProduct = () => () => {
    return function (node) {
        const {bizNodeType} = node.data

        return Boolean(
            this.BizNode[bizNodeType].canUpgrade?.(this, node)
        )
    }
}

export const upgradeProduct = () => () => {
    return async function (nodes) {
        const nodesToGrow = []

        const result = await Promise.allSettled(
            [...nodes].map(
                async node => {
                    try {
                        const _n = this.nodeProxy(node)
                        await _n.upgrade()
                        this.logger.info('升级模型制品成功', [node])

                        if (node.firstChild) {
                            nodesToGrow.push(node)
                        }
                    }
                    catch (err) {
                        this.logger.error(
                            `升级模型制品失败: ${err.message}`,
                            [node]
                        )

                        err.nodes = [node]
                        throw err
                    }
                }
            )
        )

        await this.actions.growNode(nodesToGrow, false)
        return result
    }
}
