import {css} from '@emotion/react'
import {NodePropertiesPane} from '../../SidePanel/NodePanel/NodePane.jsx'
import ProductSn from './PropertiesControllerProductSn.jsx'
import ProductVersion from './PropertiesControllerProductVersion.jsx'
import ProductStatus from './PropertiesControllerProductStatus.jsx'
import ProjectNumber from './PropertiesControllerProjectNumber.jsx'

const cssButtons = css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
})

export function BatchPropertiesPane({buttons, controllers, ...props}) {
    return (
        <NodePropertiesPane {...props}>
            {controllers}
            <div css={cssButtons}>{buttons}</div>
        </NodePropertiesPane>
    )
}

export function ComponentPropertiesPane({
    buttons,
    controllers,
    noSn,
    snSuffix,
    ...props
}) {
    return (
        <NodePropertiesPane {...props}>
            <ProjectNumber />
            {! noSn && <ProductSn labelSuffix={snSuffix} />}
            <ProductVersion />
            {controllers}
            <div css={cssButtons}>{buttons}</div>
        </NodePropertiesPane>
    )
}

export function ProductPropertiesPane({
    buttons,
    controllers,
    noSn,
    snSuffix,
    ...props
}) {
    return (
        <NodePropertiesPane {...props}>
            <ProjectNumber />
            {! noSn && <ProductSn labelSuffix={snSuffix} />}
            <ProductVersion />
            <ProductStatus />
            {controllers}
            <div css={cssButtons}>{buttons}</div>
        </NodePropertiesPane>
    )
}
