import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'cdtUserCode',
    detailUrl: '/DmCdtDetail',
    detailIdProp: 'cdtId',
    mapProp: 'cdtMap',
    name: '定制数据类型',
    textProp: 'cdtName',
    type: 'CDT',
}
