import extendNode from '../extendNode.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaCdg.mjs'
import Model from './ModelCdg.mjs'
import PropertiesPane from './PropertiesPaneCdg.jsx'
import QueryForm from './QueryFormSelectCdg.jsx'
import Table from './TableSelectCdg.jsx'
import api from './apiCdg.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(CAT|DGF)$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {dms = [], ...query}) => ({
                ...query,
                dmIds: dms.map(({dmId}) => dmId),
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        defaultChildType(map, node) {
            return 'DGF'
        },

        getIcons(map, node) {
            return map.BizNode.DT.getIcons.call(this, map, node)
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {pcdgDfList: []})
        },

        getStyle(map, node) {
            return map.BizNode.DT.getStyle.call(this, map, node)
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['pcdgDfList'])
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('DF' === bizNodeType) {
                map.BizNode.DGF.castFrom(map, child)
            }

            ProductNode.onAttached.call(this, map, node, child)
        },

        async onCreate(map, node) {
            for (const n of map.walkUp(node.parent)) {
                const {
                    bizNodeType,
                    dmCode,
                    dmId,
                    dmName,
                } = n.data

                if ('CAT_ROOT_DM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        dmCode,
                        dmId,
                        dmName,
                    }

                    break
                }
            }

            await ProductNode.onCreate.call(this, map, node)
        },
    })
}
