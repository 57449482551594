export const getNodeBoxStyle = () => () => {
    return function (node) {
        const {bizNodeType} = node.data

        const {
            innterBoxBackgroundColor,
            outerBoxBackgroundColor,
        } = {
            ...this.BizNode[bizNodeType].getStyle(this, node),
            ...node.data.style,
        }

        return {
            innterBoxBackgroundColor,
            outerBoxBackgroundColor,
        }
    }
}
