import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaBdStruct.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}
            return '2' === bdType
        },

        async onCreate(map, node) {
            node.data = {...node.data, algType: 'STRUCT'}
            await ArCatNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            if ('BD' === type) {
                data.algList.push(this._getPushData(map, node))
            }
        },
    })
}
