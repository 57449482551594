import DiffMap from './maps/DiffMap/DiffMap.jsx'
import EmbeddedMap from './maps/EmbeddedMap/EmbeddedMap.jsx'
import HierarchyMap from './maps/HierarchyMap/HierarchyMap.jsx'
import MigrateMap from './maps/MigrateMap/MigrateMap.jsx'
import NavigatorMap from './maps/NavigatorMap/NavigatorMap.jsx'
import ProductMap from './maps/ProductMap/ProductMap.jsx'
import ShareMap from './maps/ShareMap/ShareMap.jsx'
import SnapshotMap from './maps/SnapshotMap/SnapshotMap.jsx'

export default function SoftwareMap({mapData, mode, type, ...props}) {
    if ('diff' === mode) {
        return <DiffMap type={type} {...props} />
    }
    else if ('hierarchy' === mode) {
        return <HierarchyMap type={type} {...props} />
    }
    else if ('migrate' === mode && mapData) {
        return <MigrateMap mapData={mapData} {...props} />
    }
    else if ('share' === mode) {
        return <ShareMap {...props} />
    }
    else if ('snapshot' === mode) {
        return <SnapshotMap {...props} />
    }
    else if (type) {
        return <ProductMap type={type} {...props} />
    }
    else if (mapData) {
        return <EmbeddedMap mapData={mapData} {...props} />
    }
    else {
        return <NavigatorMap {...props} />
    }
}
