import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import PropertiesPane from '../CAT_ROOT_PRJ/PropertiesPaneCatRootPrj.jsx'
import meta from './metaCatRootPrjDep.mjs'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(PHYS|VR)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(CAT)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'CAT'
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['PHYS'],
                ['VR'],
            ]
        },
    })
}
