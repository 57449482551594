import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Db from '../components/PropertiesControllerDb.jsx'
import Dm from '../components/PropertiesControllerDm.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelDv.mjs'

const PropertiesPaneDv = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Db />
                <Dm />
                <Text prop="dvUserCode" />
                <Text prop="dvName" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Depend', 'Effect', 'Reference']} />}
        {...props}
    />
)

export default PropertiesPaneDv
