import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelCdtseqval.mjs'

const PropertiesPaneCdtseqval = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="cdtSeqValName" />
                <Text prop="cdtSeqVal" />
                <Text prop="cdtSeqValEn" />
                <LongText prop="cdtSeqValDesc" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneCdtseqval
