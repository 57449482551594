import {useEffect, useState} from 'react'
import http from '@/biz/http.mjs'

export default (url = '') => {
    const [result, setResult] = useState('')

    useEffect(
        () => {
            if (! url) {
                return
            }

            (async () => {
                try {
                    await http.head(url)
                    setResult(url)
                }
                catch (err) { // eslint-disable-line no-empty
                }
            })()
        },

        [url]
    )

    return result
}
