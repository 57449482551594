import http from '@/biz/http.mjs'
import {writeFileToDisk} from '@/script/file.mjs'

export const canExportNode = () => () => {
    return function (node, type) {
        return true
    }
}

export const exportNode = () => {

    return () => async function (nodes, type) {
        if (0 === nodes.length) {
            return
        }

        if (1 < nodes.length) {
            this.logger.warn(
                '只能导出单一节点，忽略除第一个节点外的其余节点',
                nodes
            )
        }

        const [node] = nodes

        const getTree = node => this.exportTree(node, ({id, ...n}) => n)

        const data = {
            data: this.data,
            root: getTree(node),
            version: 1,
        }

        const file = await http.post(`/convert-map/${type}`, data)
        const fileName = this.behaviours.getNodeText(node)

        const ext = {
            'image/png': 'png',
            'application/pdf': 'pdf',
        }[file.blob.type]

        await writeFileToDisk(
            file.blob,

            {
                excludeAcceptAllOption: true,
                suggestedName: `${fileName}.${ext}`,

                types: [{
                    accept: {[file.blob.type]: [`.${ext}`] },
                    description: `${ext.toUpperCase()} 文件`,
                }],
            }
        )

        this.logger.info('已导出节点', [node])
    }
}
