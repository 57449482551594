import {Button} from 'antd'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonImportProducts(props) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 !== selectedNodes.size) {
        return null
    }

    const [node] = [...selectedNodes]
    const {bizNodeType} = node.data

    if (! map.BizNode[bizNodeType].canWriteTree(map, node)) {
        return null
    }

    const handleClick = async () => {
        const {pkid} = map.data

        const products = await runAsync(
            () => http.get('/basmaps/diff/dp', {pkid}),
            {action: '导入制品'}
        )

        map.cmd(() => {
            const nodesToSelect = new Set

            for (const data of products) {
                const n = map.importTree({data})
                map.appendChild(map.root, n)
            }

            map.selectNodes(nodesToSelect)
        })
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            导入制品
        </Button>
    )
}
