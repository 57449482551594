import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import PsType from './PsType.jsx'
import Model from './ModelPs.mjs'

const PropertiesPanePs = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <LongText prop="psName" />

                <Select
                    options={PsType}
                    prop="psTypeCode"
                />

                <LongText prop="psDesc" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPanePs
