import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ruleUserCode',
    mapProp: 'ruleMap',
    name: '业务规则',
    ownerIdProp: 'bdId',
    ownerTextProp: 'bdName',
    ownerType: 'BD',
    textProp: 'ruleTitle',
    type: 'BDRULE',
}
