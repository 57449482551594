import extendNode from '../extendNode.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import Tree from '@/script/Tree.mjs'
import {exportTree} from '../../scripts/map.mjs'
import useDesignNode from '../_DESIGN/useDesignNode.jsx'
import findOwnerProduct from '../scripts/findOwnerProduct.mjs'
import getMapPath from '../scripts/getMapPath.mjs'
import meta from './metaComponentNode.mjs'

/**
 * 部件节点类型的基类
 *
 * 部件节点从属于制品节点
 */
export default () => {
    const DesignNode = useDesignNode()

    return extendNode(DesignNode, {
        ...meta,
        isComponent: true,

        canGrow(map, node) {
            if (! DesignNode.canGrow.call(this, map, node)) {
                return false
            }

            if (! node.data[this.ownerIdProp]) {
                map.logger.error(
                    `缺少所属制品ID，不能加载/卸载: ${this.ownerIdProp}`,
                    [node]
                )

                return false
            }

            // eslint-disable-next-line react/no-is-mounted
            if (this.isMounted(map, node)) {
                map.logger.error('定义节点不能加载/卸载', [node])
                return false
            }

            return true
        },

        canWriteNode(map, node) {
            if (! DesignNode.canWriteNode.call(this, map, node)) {
                return false
            }

            const {
                bizNodeType,
                pkid,
                [this.ownerIdProp]: ownerId,
            } = node.data

            if (! map.BizNode[bizNodeType].isMounted(map, node)) {
                return false
            }

            if (! (pkid && ownerId)) {
                return true
            }

            const owner = findOwnerProduct(map, node)

            if (! owner) {
                return false
            }

            return true
        },

        canWriteTree(map, node) {
            if (! DesignNode.canWriteTree.call(this, map, node)) {
                return false
            }

            const {
                bizNodeType,
                pkid,
                [this.ownerIdProp]: ownerId,
            } = node.data

            if (! map.BizNode[bizNodeType].isMounted(map, node)) {
                return false
            }

            if (! (pkid && ownerId)) {
                return true
            }

            const owner = findOwnerProduct(map, node)

            if (! owner) {
                return false
            }

            return true
        },

        getRev(map, node) {
            // eslint-disable-next-line react/no-is-mounted
            if (this.isMounted(map, node)) {
                return null
            }
            else {
                return DesignNode.getRev.call(this, map, node)
            }
        },

        getTextPrefix(map, node) {
            const prefix = DesignNode.getTextPrefix.call(this, map, node)

            const {
                [this.ownerIdProp]: ownerId,
                [this.ownerTextProp]: ownerText,
                pkid,
            } = node.data

            if (! (pkid && ownerId && ownerText)) {
                return prefix
            }

            const owner = findOwnerProduct(map, node)

            if (owner) {
                return prefix
            }
            else {
                return `【${ownerText}】`
            }
        },

        async getUrl(map, node, childPath = []) {
            if (! node.data.pkid) {
                return DesignNode.getUrl.call(this, map, node, childPath)
            }

            const owner = findOwnerProduct(map, node)

            if (owner) {
                return DesignNode.getUrl.call(this, map, node, childPath)
            }

            const mapPath = await getMapPath(map, node)

            if (! mapPath) {
                return DesignNode.getUrl.call(this, map, node)
            }

            const {
                [this.ownerIdProp]: ownerId = '',
                [this.ownerTextProp]: ownerText = '',
            } = node.data

            return buildUrl(
                map.BizNode[this.ownerType].detailUrl,

                {
                    mapPath,
                    [this.ownerIdProp]: ownerId,
                    [this.ownerTextProp]: ownerText,
                },
            )
        },

        async jumpToDiff(map, node, leftRev, rightRev) {
            const {
                bizNodeType,
                pkid,
                [this.ownerTextProp]: ownerText,
            } = node.data

            const {name} = map.BizNode[this.ownerType]

            const url = buildUrl(
                '/DiffMap',

                {
                    id: pkid,
                    leftRev,
                    rightRev,
                    title: `${name}【${ownerText}】`,
                    type: bizNodeType,
                }
            )

            window.open(url)
        },

        menuInsertConcept(map, nodes) {
            return this._menuInsertConcept(map, nodes)
        },

        async onAttachTo(map, node) {
            // 必要时转为概念
            to_concept: {
                const {bizNodeType} = node.data

                if (
                    ! node.data.pkid ||
                    map.BizNode[bizNodeType].isLinked(map, node)
                ) {
                    break to_concept
                }

                const owner = findOwnerProduct(map, node)

                if (! owner) {
                    this._toConcept(map, node)
                    break to_concept
                }

                const next = (chain) => {
                    const [node] = chain
                    const {bizNodeType: t} = node.data
                    const isMounted = map.BizNode[t].isMounted(map, node)

                    return {
                        yieldChildren: isMounted,
                        yieldNode: isMounted,
                    }
                }

                // 查找制品下的其他部件
                for (
                    const n of
                    map.walkDown(owner, {excludeTarget: true, next})
                ) {
                    // 相同业务 ID 的部件已存在
                    if (
                        n !== node &&
                        n.data.pkid === node.data.pkid &&
                        // PK 和 DF 使用相同的 pkid
                        n.data.bizNodeType === bizNodeType
                    ) {
                        this._toConcept(map, node)
                        break
                    }
                }
            }

            await DesignNode.onAttachTo.call(this, map, node)
        },

        async onDoubleClick(map, node, event) {
            const {
                lastRev: _1,
                pkid,
                rev: _2,
                [this.ownerIdProp]: ownerId,
            } = node.data

            if (! pkid) {
                DesignNode.onDoubleClick.call(this, map, node, event)
                return
            }

            const jumpToProduct = async () => {
                event.preventDefault()

                if (! ownerId) {
                    return
                }

                const rev = this.getRev(map, node)

                if (
                    this.isOutdated(map, node) &&
                    0 < rev
                ) {
                    this.jumpToDiff(map, node, rev)
                }
                else {
                    const q = JSON.stringify({
                        isMounted: true,
                        pkid,
                    })

                    const url = buildUrl(
                        map.BizNode[this.ownerType].detailUrl,

                        {
                            q,
                            [this.ownerIdProp]: ownerId
                        },
                    )

                    window.open(url)
                }
            }

            if (/^(ANALYSE|DEPEND|IS)_MAP$/.test(map.data.mapTypeCode)) {
                jumpToProduct()
            }
            else {
                const owner = findOwnerProduct(map, node)

                if (owner && ! owner.parent) {
                    const next = (chain) => {
                        const node = chain[0]
                        const {bizNodeType} = node.data
                        const bn = map.BizNode[bizNodeType]
                        const yieldChildren = bn.isMounted(map, node)
                        const yieldNode = bn.isMounted(map, node)
                        return {yieldChildren, yieldNode}
                    }

                    for (
                        const n of
                        map.walkDown(owner, {excludeTarget: true, next})
                    ) {
                        if (n.data.pkid === pkid) {
                            map.execute(() => map.selectNodes([n]))
                            break
                        }
                    }
                }
                else {
                    jumpToProduct()
                }
            }
        },

        async onPull(map, node) {
            await DesignNode.onPull.call(this, map, node)

            if (! this.attrNodes) {
                return
            }

            const owner = findOwnerProduct(map, node)

            if (owner && ! owner.parent) {
                await this._initAttrNodes(map, node)
            }
        },

        async readTree(map, node, rev) {
            const ownerTree = await this._readOwnerTree(map, node, rev)

            if (! ownerTree) {
                return null
            }

            const next = (chain) => {
                const node = chain[0]
                const {bizNodeType} = node.data
                const bn = map.BizNode[bizNodeType]
                const yieldChildren = bn.isMounted(ownerTree, node)
                const yieldNode = bn.isMounted(ownerTree, node)
                return {yieldChildren, yieldNode}
            }

            const {pkid} = node.data

            for (
                const n of
                ownerTree.walkDown(
                    ownerTree.root,
                    {excludeTarget: true, next}
                )
            ) {
                if (n.data.pkid === pkid) {
                    const {
                        prjId,
                        prjNo,
                        rev: rev2,
                        sVer,
                    } = ownerTree.root.data

                    n.data = {
                        ...n.data,
                        prjId,
                        prjNo,
                        rev: (! rev || 0 === rev) ? rev2 : rev,
                        sVer,
                    }

                    return exportTree(ownerTree, n)
                }
            }

            return null
        },

        async upgrade(map, node) {
            const {bizNodeType, pkid} = node.data

            const {
                ownerIdProp,
                ownerTextProp,
                ownerType,
            } = map.BizNode[bizNodeType]

            const {[ownerIdProp]: ownerId} = node.data
            const {textProp} = map.BizNode[ownerType]

            const tree = new Tree(
                await map.BizNode[ownerType].readTree({pkid: ownerId})
            )

            const {
                stsCode,
                sVer,
                [textProp]: ownerText,
            } = tree.root.data

            if (! /^(RLS|REVISE)$/.test(stsCode)) {
                throw new Error('制品不是发布/修订状态，不能升级')
            }

            const next = (chain) => {
                const node = chain[0]
                const {bizNodeType} = node.data
                const bn = map.BizNode[bizNodeType]
                const yieldChildren = bn.isMounted(tree, node)
                const yieldNode = bn.isMounted(tree, node)
                return {yieldChildren, yieldNode}
            }

            for (
                const n of
                tree.walkDown(tree.root, {excludeTarget: true, next})
            ) {
                if (pkid === n.data.pkid) {
                    node.data = {
                        ...n.data,
                        sVer,
                        [ownerTextProp]: ownerText,
                    }

                    return
                }
            }

            throw new Error('未找到部件')
        },

        async _readGrowTree(map, node) {
            const _n = map.nodeProxy(node)
            const {pkid, rev} = _n.data

            const ownerTree = await (async () => {
                const latestTree = await _n._readOwnerTree()
                const {stsCode} = latestTree.data

                if ('RLS' === stsCode) {
                    return _n._readOwnerTree(rev)
                }
                else {
                    return latestTree
                }
            })()

            const owner = map.importTree(ownerTree)
            const o = map.nodeProxy(owner)

            const nn = (() => {
                const next = chain => {
                    const _n = map.nodeProxy(chain[0])
                    const isMounted = _n.isMounted()

                    return {
                        yieldChildren: isMounted,
                        yieldNode: isMounted,
                    }
                }

                for (
                    const node of
                    map.walkDown(owner, {excludeTarget: true, next})
                ) {
                    if (node.data.pkid === pkid) {
                        const {
                            prjId,
                            prjNo,
                            rev: rev2,
                            stsCode,
                            stsName,
                            sVer,
                        } = o.data

                        const {rev} = _n.data

                        node.data = {
                            ...node.data,
                            prjId,
                            prjNo,
                            rev: (! rev || 0 === rev) ? rev2 : rev,
                            stsCode,
                            stsName,
                            sVer,
                            [this.ownerTextProp]: o.getTextRaw(),
                        }

                        return node
                    }
                }
            })()

            if (! nn) {
                throw new Error('未在部件的制品图里找到该部件，可能已被删除')
            }

            const tree = this.exportTree(map, nn)
            map.deleteTree(owner)
            return tree
        },

        async _readOwnerTree(map, node, rev) {
            const {[this.ownerIdProp]: ownerId} = node.data

            if (! ownerId) {
                throw new Error('无法读取制品图，缺少所属制品 ID')
            }

            return map.BizNode[this.ownerType].readTree({pkid: ownerId, rev})
        },
    })
}
