import extendNode from '../extendNode.mjs'
import superMeta from '../_ATTRIBUTE/metaAttributeNode.mjs'
import useAttributeNode from '../_ATTRIBUTE/useAttributeNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'

export default () => {
    const AttributeNode = useAttributeNode()

    return extendNode(AttributeNode, {
        ...superMeta,
        prop: 'dfDesc',
        type: 'ATTR_DF_DESC',

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill="#f88634"
                    letters="!"
                    textColor="#fff"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...AttributeNode.getStyle.call(this, map, node),
                shape: 'Rectangle',
            }
        },
    })
}
