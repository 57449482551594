import superMeta from '../_MODULE/metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'umUserCode',
    mapIdProp: 'uiMapId',
    mapStsCodeProp: 'uiMapStsCode',
    mapTypeCode: 'UI_MAP',
    name: '界面入口',
    ownerIdProp: 'uaId',
    ownerTextProp: 'uaName',
    ownerType: 'UA',
    textProp: 'umName',
    type: 'UM',
}
