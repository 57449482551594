import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaDesCatNode.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        canLinkType(map, node, type) {
            return map.BizNode.AR.canLinkType.call(this, map, node, type)
        },

        canMountType(map, node, type) {
            return /^(AR|CAT|DOC|PIC|URL)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return map.BizNode.AR.menuItemsInsertProduct.call(this, map, node)
        },
    })
}
