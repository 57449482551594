import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaLsiAlg.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        onPush(map, node, type, data) {
            if ('LSI' === type) {
                data.algList.push(this._getPushData(map, node))
            }
        },
    })
}
