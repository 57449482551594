import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaBfdataflow.mjs'
import PropertiesPane from './PropertiesPaneBfdataflow.jsx'
import QueryForm from './QueryFormSelectBfdataflow.jsx'
import Table from './TableSelectBfdataflow.jsx'
import api from './apiBfdataflow.mjs'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        api,
        PropertiesPane,

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.LIGHT_YELLOW}
                    letters="SS"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        onPush(map, node, type, data) {
            const key = {
                BF: 'algList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(
                map,
                node,
                {fcCode: '', sfCodes: []}
            )

            data[key].push(pushData)
        },
    })
}
