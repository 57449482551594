import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaVr.mjs'
import Model from './ModelVr.mjs'
import PropertiesPane from './PropertiesPaneVr.jsx'
import QueryForm from './QueryFormSelectVr.jsx'
import Table from './TableSelectVr.jsx'
import api from './apiVr.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(VR_DEPLOYED_(IN|ON))$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GREEN}
                    letters="|||"
                    textColor="#fff"
                />
            ]
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {depVsDeploys: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.DARK_GREEN,
                }),
            }
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ProductNode.getTextPrefix.call(
                this, map, node
            )

            const {vsEnv} = node.data

            if (vsEnv) {
                return `${defaultPrefix}【${vsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        onPush(map, node, type, data) {
            const {pkid} = node.data

            if (! pkid) {
                return
            }

            const key = {
                DBINS: 'archDbInsDeploys',
                MIDINS: 'archMiddlewareInsDeploys',
                SSINS: 'archSsInsDeploys',
            }[type]

            if (! key) {
                return
            }

            const pushData = this.getPushData(map, node)

            data[key].push({
                ...pushData,

                ...({
                    DBINS: () => {
                        const {prjId, sVer, vsCode} = node.data

                        return {
                            deployType: 'VS',
                            deployPrjId: prjId,
                            deploySVer: sVer,
                            deployCode: vsCode,
                        }
                    },

                    MIDINS: () => {
                        const {prjId, sVer, vsCode} = node.data

                        return {
                            deployType: 'VS',
                            deployPrjId: prjId,
                            deploySVer: sVer,
                            deployCode: vsCode,
                        }
                    },

                    SSINS: () => {
                        const {prjId, sVer, vsCode} = node.data

                        return {
                            deployType: 'VS',
                            deployPrjId: prjId,
                            deploySVer: sVer,
                            deployCode: vsCode,
                        }
                    },
                }[type])(),
            })
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return newDepth
            }

            for (const n of map.walkUpNoComment(node.parent)) {
                if ('CAT_ROOT_PRJ_DEP' !== n.data.bizNodeType) {
                    break
                }

                for (const n of [...node.children]) {
                    if ('VR_DEPLOYED_ON' === n.data.bizNodeType) {
                        map.deleteNode(n)
                    }
                    else {
                        map.deleteTree(n)
                    }
                }
            }

            return newDepth
        },
    })
}
