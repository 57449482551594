import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'progUserCode',
    name: '关键逻辑',
    ownerIdProp: 'lsiId',
    ownerTextProp: 'lsiName',
    ownerType: 'LSI',
    textProp: 'progTitle',
    type: 'IPROG',
}
