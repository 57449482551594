import superMeta from '../_ARCH/metaArchNode.mjs'

export default {
    ...superMeta,
    archUrl: '/ProjectSfMap',
    detailUrl: '/FaDetail',
    detailIdProp: 'faId',
    mapProp: 'faMap',
    name: '功能地图',
    type: 'FA',
}
