import extendNode from '../extendNode.mjs'
import useDesignNode from '../_DESIGN/useDesignNode.jsx'
import meta from './metaAbstractNode.mjs'

export default () => {
    const DesignNode = useDesignNode()

    return extendNode(DesignNode, {
        ...meta,

        menuInsertConcept() {
            return null
        },
    })
}
