import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    detailUrl: '/DepPsDetail',
    detailIdProp: 'psId',
    mapProp: 'map',
    name: '物理机',
    textProp: 'psName',
    type: 'PHYS',
}
