import extendNode from '../extendNode.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import meta from './metaPseudoNode.mjs'

export default () => {
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,

        canWriteNode() {
            return false
        },

        canWriteTree() {
            return false
        },

        getDesc(map, node) {
            return ''
        },
    })
}
