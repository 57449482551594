import useSWR from 'swr/immutable'
import {ReadOnlyContext} from '@/components/Form/ReadOnly.mjs'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import ButtonImportProducts from '../components/PropertiesPaneButtonImportProducts.jsx'
import SidePane from '../../SidePanel/SidePane.jsx'
import {PropertiesContext} from '../../SidePanel/NodePanel/Properties.mjs'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'

const useVm = (vmId) => {

    const fetchVm = (vmId) => runAsync(
        () => http.get(`/archvms/${vmId}`),

        {
            error: {
                content: (err) => `读取程序模块失败: ${err.message}`,
            },

            loading: {
                content: '正在读取程序模块，请稍候',
            },
        }
    )

    const {data} = useSWR(vmId, fetchVm)
    return data
}

const PropertiesPaneCatRootVm = ({nodes, ...props}) => {
    const vm = useVm(nodes[0].data.vmId)

    if (! vm) {
        return null
    }

    return (
        <PropertiesContext.Provider value={[vm]}>
            <ReadOnlyContext.Provider value={true}>
                <SidePane {...props}>
                    <Text
                        prop="vmUserCode"
                        title="程序模块代码"
                    />

                    <Text
                        prop="vmName"
                        title="程序模块名称"
                    />

                    <div>
                        <ButtonImportProducts />
                    </div>
                </SidePane>
            </ReadOnlyContext.Provider>
        </PropertiesContext.Provider>
    )
}

export default PropertiesPaneCatRootVm
