export const canGrowNode = () => () => {
    return function (node) {
        const {bizNodeType} = node.data
        return this.BizNode[bizNodeType].canGrow(this, node)
    }
}

export const growNode = () => () => {
    return function (nodes, recursive) {
        return Promise.allSettled(
            [...nodes].map(
                async node => {
                    try {
                        const _n = this.nodeProxy(node)
                        const options = recursive ? {maxDepth: 3} : void 0
                        await _n.grow(options)
                        this.logger.info('加载节点成功', [node])
                    }
                    catch (err) {
                        this.logger.error(
                            `加载节点失败: ${err.message}`,
                            [node]
                        )

                        err.nodes = [node]
                        throw err
                    }
                }
            )
        )
    }
}
