import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import meta from './metaCatRootDep.mjs'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^DEP_(DB|MID|SS)$/.test(type)
        },
    })
}
