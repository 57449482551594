export const canCreateMap = () => () => {
    return function (node) {
        const {bizNodeType, pkid} = node.data
        const {mapIdProp} = this.BizNode[bizNodeType]
        const {[mapIdProp]: mapId} = node.data

        if (! pkid) {
            this.logger.error('只有制品才能创建地图', [node])
            return false
        }

        if (mapId) {
            this.logger.error('已创建过地图，不能重复创建', [node])
            return false
        }

        return true
    }
}

export const createMap = () => () => {
    return function (nodes) {
        return Promise.allSettled(
            [...nodes].map(
                async node => {
                    try {
                        const _n = this.nodeProxy(node)
                        const updates = await _n.createMap()
                        this.actions.updateNodeData([node], () => updates)
                        this.logger.info('创建地图成功', [node])
                    }
                    catch (err) {
                        this.logger.error(
                            `创建地图失败: ${err.message}`,
                            [node]
                        )

                        err.nodes = [node]
                        throw err
                    }
                }
            )
        )
    }
}
