import {css} from '@emotion/react'
import useLauncher from '@/components/Launcher/Launcher.jsx'

const cssLauncherItem = css({
    padding: 4,
})

const LauncherItem = ({name, type}) => (
    <div css={cssLauncherItem}>{type} {name}</div>
)

export const quickInsertProduct = () => {
    const openLauncher = useLauncher()

    return () => async function (nodes) {
        if (0 === nodes.length) {
            return
        }

        const insert = (type) => {
            this.commands.insertProduct(nodes, type)
        }

        const menuItems = nodes.map(node => {
            const {bizNodeType} = node.data
            return this.BizNode[bizNodeType].menuItemsInsertProduct(this, node)
        })

        const itemTypes = menuItems.map(
            items => new Set(
                items.map(([type]) => type)
            )
        )

        let commonTypes = new Set(itemTypes[0])

        for (let i = 1; i < menuItems.length; i += 1) {
            commonTypes = commonTypes.intersection(itemTypes[i])
        }

        const types = menuItems[0]
            .filter(
                ([type]) => (
                    this.BizNode[type].readList &&
                    commonTypes.has(type)
                )
            )
            .map(
                ([type, label = this.BizNode[type].name]) => [type, label]
            )

        if (1 < types.length) {
            const type = await openLauncher({
                getItems: (hint) => {
                    return types
                        .filter(
                            ([type]) => type.startsWith(hint.toUpperCase())
                        )
                        .map(
                            ([type, name]) => (
                                <LauncherItem
                                    key={type}
                                    name={name}
                                    type={type}
                                />
                            ),
                        )
                },

                placeholder: '输入代码快速插入模型制品',
            })

            if (type) {
                insert(type)
            }
        }
        else if (0 < types.length) {
            insert(types[0][0])
        }
    }
}
