import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'dtoUserCode',
    detailUrl: '/DtoDetail',
    detailIdProp: 'dtoId',
    mapProp: 'dtoMap',
    name: 'DTO',
    textProp: 'dtoName',
    type: 'DTO',
}
