import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'cdtSeqValName',
    name: '定制数据类型序列',
    ownerIdProp: 'cdtId',
    ownerTextProp: 'cdtName',
    ownerType: 'CDT',
    textProp: 'cdtSeqVal',
    type: 'CDTSEQVAL',
}
