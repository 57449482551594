import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaPhysDeployedOn.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(PHYS_DEPLOYED_((DB|MID|SS)INS|VR))$/.test(type)
        },
    })
}
