import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaBdlogic_1.mjs'
import QueryForm from './QueryFormSelectBdlogic_1.jsx'
import Table from './TableSelectBdlogic_1.jsx'
import api from './apiBdlogic_1.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,

        async chooseProduct(map, node) {
            const getQuery = (map, node, query) => {
                const {bizNodeType, bdNo, svrNo} = map.root.data

                if ('BD' === bizNodeType) {
                    return {bdNo, ...query}
                }
                else if ('SVR' === bizNodeType) {
                    return {bdNo: svrNo, ...query}
                }
                else {
                    return query
                }
            }

            const items = await this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )

            return items.map((item) => {
                const {bdId, svrId} = item.data

                const parentType = (() => {
                    if (bdId) {
                        return 'BD'
                    }
                    else if (svrId) {
                        return 'SVR'
                    }
                    else {
                        return ''
                    }
                })()

                const bizNodeType = parentType + 'LOGIC'
                return {...item, data: {...item.data, bizNodeType}}
            })
        },
    })
}
