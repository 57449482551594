import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import TagSelect from '../../SidePanel/NodePanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from '../_ALG/ModelAlg.mjs'

const PropertiesPaneUiAlg = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="algTitle" />
                <Text prop="algUserCode" />
                <TagSelect prop="algKey" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Depend', 'Effect', 'Reference']} />}
        {...props}
    />
)

export default PropertiesPaneUiAlg
