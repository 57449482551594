export const canCopyTree = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canCopyTree(node) &&
            this.BizNode[bizNodeType].canCopyTree(this, node)
        )
    }
}
