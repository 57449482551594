import http from '@/biz/http.mjs'

export default {

    create: (data) => http.post('/dmdvs', data),

    publish: (data) => http.put(
        '/dmdvs/rlsandrevise',
        {...data, stsCode: 'RLS'}
    ),

    push: (data) => http.post('/dmdvs/batchmodify', data),
    //pull: ({pkid}) => http.get(`/dmdvs/${pkid}/dps`),
    read: ({pkid}) => http.get(`/dmdvs/${pkid}`),
    readList: (args) => http.get('/dmdvs', {...args, crossFlag: true}),

    revise: ({pkid}) => http.put(
        '/dmdvs/rlsandrevise',
        {pkid, stsCode: 'REVISE'}
    ),

    update: (data) => http.put(`/dmdvs/${data.pkid}`),
    updateMap: (data) => http.put(`/dmdvs/${data.pkid}/map`, data),
}
