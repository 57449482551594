import runAsync from '@/script/runAsync.mjs'
import apiShare from '../../apis/apiShare.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default (id) => {
    const {data} = useSWR(
        `/maps/${id}/share`,

        () => runAsync(
            () => apiShare.read({id}),
            {action: '读取地图'}
        )
    )

    if (data) {
        return parseMap(data)
    }
    else {
        return void 0
    }
}
