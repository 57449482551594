import extendNode from '../extendNode.mjs'
import useParamNode from '../_PARAM/useParamNode.jsx'
import meta from './metaReq.mjs'

export default () => {
    const ParamNode = useParamNode()

    return extendNode(ParamNode, {
        ...meta,

        onPush(map, node, type, data) {
            if ('FC' === type) {
                data.reqList.push(
                    this.getPushData(map, node)
                )
            }
        },
    })
}
