import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import TagSelect from '../../SidePanel/NodePanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelUirule.mjs'
import UiruleType from './UiruleType.jsx'

const PropertiesPaneUirule = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="ruleTitle" />
                <Text prop="ruleUserCode" />

                <Select
                    options={UiruleType}
                    prop="ruleType"
                />

                <TagSelect prop="ruleKey" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Depend', 'Effect', 'Reference']} />}
        {...props}
    />
)

export default PropertiesPaneUirule
