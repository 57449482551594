import extendNode from '../extendNode.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import meta from './metaCommonNode.mjs'

/**
 * 通用节点类型的基类
 *
 * 通用节点可通过「插入通用节点」的方式插入地图
 */
export default () => {
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,

        menuInsertCommon(map, nodes) {
            return this._menuInsertCommon(map, nodes)
        },

        _menuInsertCommon(
            map,
            nodes,

            {
                key = this.type,
                label = this.name,

                onClick = () => {
                    map.commands.insertBizNode(
                        map.selectedNodes,
                        this.type
                    )
                },

                rank = Infinity,
            } = {}
        ) {
            for (const node of nodes) {
                const {bizNodeType} = node.data
                const bn = map.BizNode[bizNodeType]

                if (! bn.canMountType(map, node, this.type)) {
                    return null
                }
            }

            return [rank, {key, label, onClick}]
        }
    })
}
