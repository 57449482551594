import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    detailUrl: '/LsDetail',
    mapProp: 'lsMap',
    detailIdProp: 'lsId',
    name: '外联系统',
    textProp: 'lsName',
    type: 'LS',
}
