import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'

const TableSelectSf = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
            component: <Table.ViewText />,
        },

        {
            title: '项目名称',
            dataIndex: 'prjName',
            component: <Table.ViewText />,
        },

        {
            title: '功能代码',
            dataIndex: 'sfNo',
            component: <Table.ViewText />,
        },

        {
            title: '功能名称',
            dataIndex: 'sfName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/sf_1s"
            query={query}
            {...props}
        />
    )
}

export default TableSelectSf
