import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ssUserCode',
    detailUrl: '/SsDetail',
    detailIdProp: 'ssId',
    mapProp: 'ssMap',
    name: '子系统',
    textProp: 'ssName',
    type: 'SS',
}
