import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaFopr.mjs'
import OprType from './OprType.jsx'
import Model from './ModelFopr.mjs'
import PropertiesPane from './PropertiesPaneFopr.jsx'

const OprTypes = new Map(
    OprType.flat().map(([type, name, icon]) => [type, {icon, name, type}])
)

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(SF)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(FRES)$/.test(type)
        },

        getDesc(map, node) {
            const {oprType} = node.data

            if (! oprType || 'GENERAL' === oprType) {
                return this.name
            }

            const {name = ''} = OprTypes.get(oprType) ?? {}
            return name
        },

        defaultChildType(map, node) {
            return 'FRES'
        },

        getIcons(map, node) {
            const type2icon = new Map(
                OprType.flat().map(([type, , icon]) => [type, icon])
            )

            const icon = type2icon.get(node.data.oprType)

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getRev(map, node) {
            return null
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.GOLD}),
                shape: 'HorizontalHexagon',
            }
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['SF_1'],
            ]
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('CRES' === bizNodeType) {
                map.BizNode.FRES.castFrom(map, child)
            }

            ComponentNode.onAttached.call(this, map, node, child)
        },

        async onCreate(map, node) {
            node.data = {...node.data, oprType: 'GENERAL'}
            await ComponentNode.onCreate.call(this, map, node)
        },

        async onPull(map, node) {
            await ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            let dataKey, oprCat, oprScene, sceneNo

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType} = n.data

                if ('CAT' === bizNodeType) {
                    if (! oprCat) {
                        oprCat = map.BizNode.CAT.getTextRaw(map, n)
                    }
                }
                else if (map.BizNode[bizNodeType].isComment) {
                    continue
                }
                else if ('SF_MAIN' === bizNodeType) {
                    dataKey = 'mainOprList'
                    break
                }
                else if ('SCENE' === bizNodeType) {
                    dataKey = 'otherOprList'
                    oprScene = map.BizNode.SCENE.getTextRaw(map, n)
                    sceneNo = String(n.id)
                    break
                }
            }

            if (dataKey) {
                data[dataKey].push({
                    ...this._getPushData(map, node, {
                        child: [],
                        sfOprSfs: [],
                    }),

                    oprCat,
                    oprScene,
                    sceneNo,
                })
            }
        },
    })
}
