const modules = import.meta.glob(
    './nodes/*/meta*.mjs',

    {
        import: 'default',
        eager: true,
    }
)

export default Object.fromEntries(
    Object.values(modules)
        .filter(e => e.type)
        .map(e => [e.type, e])
)
