export const canPublishProduct = () => () => {
    return function (node) {
        const {bizNodeType, pkid, stsCode} = node.data

        if (! pkid) {
            this.logger.error('只有制品才能发布', [node])
            return false
        }

        if (! /^(DRAFT|REVISE)$/.test(stsCode)) {
            this.logger.error('只有草稿/修订状态的制品才能发布', [node])
            return false
        }

        const bn = this.BizNode[bizNodeType]

        if (! bn.publish) {
            this.logger.error(`${bn.name}不支持发布`, [node])
            return false
        }

        return (
            ! node.parent ||
            bn.isMounted(this, node)
        )
    }
}

export const publishProduct = () => () => {
    return function (nodes, extra) {
        return Promise.allSettled(
            [...nodes].map(
                async node => {
                    try {
                        const _n = this.nodeProxy(node)

                        const {
                            dppChangeList,
                            ...updates
                        } = await _n.publish(extra)

                        node.data = {...node.data, ...updates}

                        if (dppChangeList) {
                            const componentUpdates = new Map(
                                dppChangeList.map(e => {
                                    const {pkid, rev} = e
                                    return [pkid, {rev}]
                                })
                            )

                            const next = chain => {
                                const [node] = chain
                                const {pkid} = node.data
                                const yieldNode = componentUpdates.has(pkid)
                                return {yieldNode}
                            }

                            for (
                                const n of
                                this.walkDown(node, {excludeTarget: true, next})
                            ) {
                                const {bizNodeType, pkid} = n.data
                                const {rev} = componentUpdates.get(pkid)

                                const isMounted = this.BizNode[bizNodeType].isMounted(
                                    this, n
                                )

                                if (isMounted) {
                                    n.data = {...n.data, lastRev: rev, rev}
                                }
                                else {
                                    n.data = {...n.data, lastRev: rev}
                                }
                            }
                        }

                        this.logger.info('发布模型制品成功', [node])

                        // TEMP
                        if (! node.parent) {
                            location.reload()
                        }
                    }
                    catch (err) {
                        this.logger.error(
                            `发布模型制品失败: ${err.message}`,
                            [node]
                        )

                        err.nodes = [node]
                        throw err
                    }
                }
            )
        )
    }
}
