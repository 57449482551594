import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaDbDa.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(DM)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'DM'
        },

        onAttached(map, node, child) {
            map.BizNode.DM.onAttached(map, node, child)
        },
    })
}
