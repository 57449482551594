import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaUiVariable.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        async onPull(map, node) {
            await ArCatNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            if ('UI' === type) {
                data.varAlgList.push(this._getPushData(map, node))
            }
        },
    })
}
