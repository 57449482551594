import http from '@/biz/http.mjs'

export default {

    create: (data) => http.post('/archlss', data),

    publish: (data) => http.put(
        '/archlss/rlsandrevise',
        {...data, stsCode: 'RLS'}
    ),

    read: ({pkid}) => http.get(`/archlss/${pkid}`),
    readList: (args) => http.get('/archlss', args),

    revise: ({pkid}) => http.put(
        '/archlss/rlsandrevise',
        {pkid, stsCode: 'REVISE'}
    ),

    update: (data) => http.put(`/archlss/${data.pkid}`, data),
    updateMap: (data) => http.put(`/archlss/${data.pkid}/map`, data),
}
