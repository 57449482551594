import extendNode from '../extendNode.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaSvr.mjs'
import Model from './ModelSvr.mjs'
import PropertiesPane from './PropertiesPaneSvr.jsx'
import QueryForm from './QueryFormSelectSvr.jsx'
import Table from './TableSelectSvr.jsx'
import api from './apiSvr.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(SVR_(BASE|DEVELOP|EVENT|LOGIC|RULE|SU|SUMMARY|TERM))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {bms = [], ...query}) => ({
                ...query,
                bmIds: bms.map(({bmId}) => bmId),
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        defaultChildType(map, node) {
            if ('BDM_MAP' === map.data.mapTypeCode) {
                return 'UI'
            }
            else {
                return ''
            }
        },

        getIcons(map, node) {
            const {svrType} = node.data

            if ('2' === svrType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="A"
                        textColor="#000"
                    />
                ]
            }
            else if ('3' === svrType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="D"
                        textColor="#000"
                    />
                ]
            }
            else {
                return []
            }
        },

        getPushData(map, node) {
            return this._getPushData(
                map,
                node,

                {
                    algList: [],
                    eventList: [],
                    ruleList: [],
                    sfList: [],
                    termList: [],
                }
            )
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'DoubleBreakangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(
                data,
                ['algList', 'eventList', 'ruleList', 'termList']
            )
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                BF: 'svrList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this.getPushData(map, node)
            data[key].push(pushData)
        },

        async onSetData(map, node, oldData) {
            await ProductNode.onSetData.call(this, map, node, oldData)

            if (node.data.svrType !== oldData.svrType) {
                // 强制子节点重新渲染

                node.change('children')

                for (const n of map.children(node)) {
                    n.change('data')
                    n.change('parent')
                }
            }
        },

        async _readGrowTree(map, node) {
            return map.BizNode.BD._readGrowTree.call(this, map, node)
        },
    })
}
