import useBizNode from '../useBizNode.mjs'

export default () => {
    const BizNode = useBizNode()

    const extensions = () => ({
        get BizNode() {
            return BizNode
        },

        nodeProxy(node) {
            if (! node) {
                return null
            }

            return new Proxy(node, {
                get: (target, prop) => {
                    // bizNodeType 可能会改变，故每次方法调用都要重新获取
                    const {bizNodeType} = node.data
                    const bn = BizNode[bizNodeType]

                    if (prop in bn) {
                        const value = bn[prop]

                        if ('function' === typeof value) {
                            return value.bind(bn, this, target)
                        }
                        else {
                            return value
                        }
                    }
                    else {
                        return target[prop]
                    }
                },
            })
        }
    })

    return {extensions}
}
