import {css} from '@emotion/react'
import {Button} from 'antd'
import InputText from '@/components/Form/InputText.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import Input from '../../SidePanel/NodePanel/PropertiesControllerInput.jsx'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'

const ButtonDiscard = ({permission, ...props}) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    map.useNodesChange(selectedNodes)
    const {canDiscardProduct, discardProduct} = map.commands
    const nodes = canDiscardProduct(selectedNodes)

    if (
        0 === nodes.length ||
        ! permission.has('write')
    ) {
        return null
    }

    const handleClick = () => discardProduct(nodes)

    return (
        <Button
            onClick={handleClick}
            {...props}
        >废弃</Button>
    )
}

const ButtonPublish = ({permission, ...props}) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    map.useNodesChange(selectedNodes)
    const {canPublishProduct, publishProduct} = map.commands
    const nodes = canPublishProduct(selectedNodes)

    if (
        0 === nodes.length ||
        ! permission.has('write')
    ) {
        return null
    }

    const handleClick = () => publishProduct(nodes)

    return (
        <Button
            onClick={handleClick}
            {...props}
        >发布</Button>
    )
}

const ButtonRevise = ({permission, ...props}) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    map.useNodesChange(selectedNodes)
    const {canReviseProduct, reviseProduct} = map.commands
    const nodes = canReviseProduct(selectedNodes)

    if (
        0 === nodes.length ||
        ! permission.has('revise')
    ) {
        return null
    }

    const handleClick = () => reviseProduct(nodes)

    return (
        <Button
            onClick={handleClick}
            {...props}
        >修订</Button>
    )
}

const ButtonStatus = ({bizNodeType, stsCode, ...props}) => {
    const map = useMapContext()
    const permission = map.usePermission()

    if (
        ! stsCode ||
        'DISCARD' === stsCode ||
        ! bizNodeType ||

        [...map.selectedNodes].some(n => {
            return n.parent && ! map.BizNode[bizNodeType].isMounted(map, n)
        })
    ) {
        return null
    }

    const Component = (() => {
        if (/^(DRAFT|REVISE)$/.test(stsCode)) {
            return ButtonPublish
        }
        else if ('DES' === bizNodeType) {
            return ButtonDiscard
        }
        else {
            return ButtonRevise
        }
    })()

    return (
        <Component
            permission={permission}
            {...props}
        />
    )
}

const cssStatus = css({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'end',
    gap: 4,
})

export default function PropertiesControllerProductStatus({
    className,
    style,
    ...props
}) {
    const [{bizNodeType, stsCode, stsName}] = useProperties()

    return (
        <Input
            className={className}
            style={style}
            title="状态"
        >
            <div css={cssStatus}>
                <InputText
                    readOnly
                    value={stsName}
                    {...props}
                />

                <ButtonStatus
                    bizNodeType={bizNodeType}
                    stsCode={stsCode}
                />
            </div>
        </Input>
    )
}
