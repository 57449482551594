import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaUiOutput.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        onPush(map, node, type, data) {
            if ('UI' === type) {
                data.outputAlgList.push(this._getPushData(map, node))
            }
        },
    })
}
