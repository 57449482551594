import http from '@/biz/http.mjs'

export default {
    create: async ({
        mapName,
        mapNo,
        mapTypeCode,
        prjId,
        rNodeDataId,
    }) => {
        const {
            mapStsCode,
            mapStsName,
            mapVer,
            pkid,
        } = await http.post('/basmaps', {
            mapName,
            mapNo,
            mapTypeCode,
            prjId,
            rNodeDataId,
        })

        return {
            pkid,
            mapStsCode,
            mapStsName,
            mapVer,
        }
    },

    publish: (args) => http.put(
        '/basmaps/rlsandrevise',
        {...args, stsCode: 'RLS'}
    ),

    read: ({pkid}) => http.get(`/basmaps/${pkid}`),

    revise: (args) => http.put(
        '/basmaps/rlsandrevise',
        {...args, stsCode: 'REVISE'}
    ),

    save: async ({mapModel, pkid, ...data}) => {
        const {
            mapModel: _,
            ...newData
        } = await http.put(
            `/basmaps/${pkid}`,

            {
                ...data,
                mapModel: JSON.stringify(mapModel),
                pkid,
            },
        )

        return newData
    },
}
