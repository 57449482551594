export const pasteTreeAfter = () => () => {
    return async function (nodes) {
        const data = await this.actions.readTreeFromClipboard()
        const nodesToSelect = new Set()
        const newNodes = []

        for (const node of nodes) {
            for (const tree of data.trees) {
                const sibling = this.importTree(tree)
                this.insertSiblingAfter(node, sibling)
                newNodes.push(sibling)
            }
        }

        await Promise.all(
            newNodes.map(async n => {
                const {bizNodeType} = n.data
                await this.BizNode[bizNodeType].onAttachTo(this, n)

                if (! n.isDeleted) {
                    this.logger.info('从剪贴板粘贴', [n])
                    nodesToSelect.add(n)
                }
            })
        )

        this.selectNodes(nodesToSelect)
    }
}

export const pasteTreeAppend = () => () => {
    return async function (nodes) {
        const data = await this.actions.readTreeFromClipboard()
        const nodesToSelect = new Set()
        const newNodes = []

        for (const node of nodes) {
            for (const tree of data.trees) {
                const child = this.importTree(tree)
                this.appendChild(node, child)
                newNodes.push(child)
            }
        }

        await Promise.all(
            newNodes.map(async n => {
                const {bizNodeType} = n.data
                await this.BizNode[bizNodeType].onAttachTo(this, n)

                if (! n.isDeleted) {
                    this.logger.info('从剪贴板粘贴', [n])
                    nodesToSelect.add(n)
                }
            })
        )

        this.selectNodes(nodesToSelect)
    }
}
