export const canUpdateNodeStyle = () => defaultActions => {
    return function (node) {
        if (! defaultActions.canUpdateNodeStyle(node)) {
            return false
        }

        const n = this.nodeProxy(node)
        return n.canWriteStyle()
    }
}
