export function onClickNode(node, event) {
    event.stopPropagation()
    const isPreserve = event.ctrlKey || event.metaKey
    const isRange = event.shiftKey
    const {selectedNodes} = this

    if (this.isNodeEditingText(node)) {
        return
    }

    const selectNodes = nodes => this.execute(
        () => this.selectNodes(nodes)
    )

    if (selectedNodes.has(node)) {
        if (isPreserve) {
            const nodes = new Set(selectedNodes)
            nodes.delete(node)
            selectNodes(nodes)
        }
        else {
            selectNodes([node])
        }
    }
    else {
        if (isPreserve) {
            const nodes = new Set(selectedNodes)
            nodes.add(node)
            selectNodes(nodes)
        }
        else if (isRange) {
            if (1 === selectedNodes.size) {
                if (node.parent) {
                    const selectedNode = [...selectedNodes][0]

                    if (node.parent === selectedNode.parent) {
                        const siblings = [...this.children(node.parent)]
                        const i = siblings.indexOf(node)
                        const j = siblings.indexOf(selectedNode)

                        const nodes = i < j ?
                            siblings.slice(i, j + 1) :
                            siblings.slice(j, i + 1)

                        selectNodes(nodes)
                    }
                    else {
                        const nodes = new Set(selectedNodes)
                        nodes.add(node)
                        selectNodes(nodes)
                    }
                }
                else {
                    const nodes = new Set(selectedNodes)
                    nodes.add(node)
                    selectNodes(nodes)
                }
            }
            else {
                const nodes = new Set(selectedNodes)
                nodes.add(node)
                selectNodes(nodes)
            }
        }
        else {
            selectNodes([node])
        }
    }
}
