import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import meta from './metaBdalg.mjs'
import PropertiesPane from './PropertiesPaneBdalg.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchBdalg.jsx'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        PropertiesPane,
        PropertiesPaneBatch,

        canMountType(map, node, type) {
            return /^(ALG_(ALG|API|RULE))$/.test(type)
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'HorizontalHexagon',
            }
        },

        async onCreate(map, node) {
            const childTypes = [
                'ALG_RULE',
                'ALG_ALG',
                'ALG_API',
            ]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await AlgNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                BD: 'algList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(
                map,
                node,
                {fcCode: '', sfCodes: []}
            )

            data[key].push(pushData)
        },
    })
}
