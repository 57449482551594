import parseMap from '../../scripts/parseMap.mjs'
import BaseMap from '../BaseMap.jsx'

export default function EmbeddedMap({mapData, ...props}) {
    const initData = parseMap(mapData)

    const components = {
        NavPanel: () => null,
        ToolBar: () => null,
    }

    return (
        <BaseMap
            components={components}
            initData={initData}
            {...props}
        />
    )
}
