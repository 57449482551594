import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaDbins_.mjs'
import QueryForm from '../DBINS/QueryFormSelectDbins.jsx'
import Table from '../DBINS/TableSelectDbins.jsx'
import api from './apiDbins_.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,

        async chooseProduct(map, node) {
            const items = await this._chooseProduct(
                map, node, QueryForm, Table
            )

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'DBINS'},
            }))
        },
    })
}
