import runAsync from '@/script/runAsync.mjs'
import useBizNode from '../../useBizNode.mjs'
import useProject from '../useProject.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default (pkid, type) => {
    const BizNode = useBizNode()

    const {data: mapData} = useSWR(
        `maps/${pkid}/hierarchy`,

        () => runAsync(
            () => BizNode[type].readHierarchyMap({pkid}),
            {action: '读取地图'}
        )
    )

    const {data: project} = useProject(mapData?.data.prjId)

    if (mapData && project) {
        const {prjName, prjNo, sVer} = project

        return {
            ...mapData,
            data: {...mapData.data, prjName, prjNo, project, sVer},
        }
    }
    else {
        return undefined
    }
}
