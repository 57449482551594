import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'fjUserCode',
    name: '关键逻辑',
    ownerIdProp: 'fcId',
    ownerTextProp: 'fcName',
    ownerType: 'FC',
    textProp: 'fjTitle',
    type: 'FJ',
}
