export const saveMap = () => () => {
    return function () {
        return this.runAction(
            () => {
                const {bizNodeType} = this.root.data
                return this.BizNode[bizNodeType].updateMigrate(this, this.root)
            },

            {
                action: '保存地图',
                success: {show: true},
            },
        )
    }
}
