import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaLsiSf.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            //return /^(SF)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['SF_'],
            ]
        },

        onAttached(map, node, child) {
            const {bizNodeType, prjId} = child.data

            if ('SF' === bizNodeType) {
                if (prjId === map.data.prjId) {
                    map.deleteTree(child)
                    map.logger.error('只能插入外部项目的节点', [node])
                }
                else {
                    const oldSf = (() => {
                        for (const n of map.children(node)) {
                            if (
                                n !== child &&
                                'SF' === n.data.bizNodeType
                            ) {
                                return n
                            }
                        }
                    })()

                    if (oldSf) {
                        map.unlinkTree(child)
                        map.insertSiblingAfter(oldSf, child)
                        map.deleteTree(oldSf)
                        map.logger.warn(`只能有一个${map.BizNode.SF.name}子节点，覆盖原有节点`, [node])
                    }
                }
            }

            CategoryNode.onAttached.call(this, map, node, child)
        },

        onPush(map, node, type, data) {
            if ('LSI' !== type) {
                return
            }

            Object.assign(
                data,

                {
                    openPrjId: '',
                    openSVer: '',
                    sfCode: '',
                    sfRev: '',
                }
            )

            for (const child of map.children(node)) {
                const {
                    bizNodeType,
                    sfCode,
                    prjId: openPrjId,
                    rev: sfRev,
                    sVer: openSVer,
                } = child.data

                if ('SF' === bizNodeType) {
                    Object.assign(data, {openPrjId, openSVer, sfCode, sfRev})
                    break
                }
            }
        },
    })
}
