import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Check from '../../SidePanel/NodePanel/PropertiesControllerCheck.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import DataType from './PropertiesControllerDataType.jsx'
import DataTypeGrpCode from './PropertiesControllerDataTypeGrpCode.jsx'
import Df from './PropertiesControllerDf.jsx'
import Model from './ModelDtf.mjs'

const PropertiesPaneDtf = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Check prop="isDtDf" />
                <Df />
                <Text prop="dfUserCode" />
                <Text prop="dfName" />
                <DataTypeGrpCode />
                <DataType />
                <LongText prop="dfDesc" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneDtf
