import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    catProp: 'dfCat',
    codeProp: 'dfUserCode',
    name: '属性',
    ownerIdProp: 'dtoId',
    ownerTextProp: 'dtoName',
    ownerType: 'DTO',
    textProp: 'dfName',
    type: 'DTF',
}
