import {Button} from 'antd'
import ContextMenu from '@/components/ContextMenu/ContextMenu.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {PopoverTrigger} from '@/components/Popover/index.mjs'
import buildUrl from '@/script/buildUrl.mjs'

const mode2name = {
    CallUiDepend: '界面间依赖分析',
    CallUiEffect: '界面间影响分析',
    Depend: '依赖分析',
    Effect: '影响分析',
    FkDepend: '外键依赖分析',
    FkEffect: '外键影响分析',
    Reference: '引用分析',
}

const order = [
    'Effect',
    'CallUiEffect',
    'FkEffect',
    'Depend',
    'CallUiDepend',
    'FkDepend',
    'Reference',
]

const AnalyseMultiple = ({items, ...props}) => {
    const menuItems = items.map(([label, url]) => ({
        label,
        onClick: () => window.open(url),
    }))

    return (
        <PopoverTrigger
            align="right"
            content={<ContextMenu items={menuItems} />}
            position="bottom"
            {...props}
        >
            <Button
                size="small"
                type="link"
            >
                模件分析
            </Button>
        </PopoverTrigger>
    )
}

const AnalyseSingle = ({children, item, ...props}) => {
    const [label, url] = item
    const handleClick = () => window.open(url)

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            {label}
        </Button>
    )
}

export default function PropertiesControllerAnalyse({modes, ...props}) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 !== selectedNodes.size) {
        return null
    }

    const [node] = selectedNodes

    const {
        bizNodeType,
        dpSn,
        pkid,
        prjNo,
        rev,
        sVer,
    } = node.data

    const {textProp, ownerTextProp} = map.BizNode[bizNodeType]

    const {
        [ownerTextProp]: dpName,
        [textProp]: name,
    } = node.data

    const items = modes
        .toSorted((a, b) => {
            const ia = order.indexOf(a)
            const ib = order.indexOf(b)
            return ia - ib
        })
        .map(mode => [
            mode2name[mode],

            buildUrl(
                '/ModuleAnalyse',

                {
                    dpName,
                    name,
                    bizNodeType,
                    dpSn,
                    mode,
                    pkid,
                    prjNo,
                    rev,
                    sVer,
                }
            )
        ])

    if (1 < items.length) {
        return <AnalyseMultiple items={items} {...props} />
    }
    else {
        return <AnalyseSingle item={items[0]} {...props} />
    }
}
