import usePreviewUi from './usePreviewUi.mjs'

export default () => {
    const previewUi = usePreviewUi()

    return (map, nodes) => {
        if (1 < nodes.size) {
            return []
        }

        const onClick = async () => {
            const [ui] = nodes
            previewUi(map, ui)
        }

        return [
            {
                key: 'preview',
                label: '预览',
                onClick,
            }
        ]
    }
}
