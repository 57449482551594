import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaSf_1.mjs'
import QueryForm from './QueryFormSelectSf_1.jsx'
import Table from './TableSelectSf_1.jsx'
import api from './apiSf_1.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,

        async chooseProduct(map, node) {
            const items = await this._chooseProduct(
                map, node, QueryForm, Table, {multiple: false}
            )

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'SF'},
            }))
        },
    })
}
