import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import meta from './metaCategoryNode.mjs'

/**
 * 预置分类节点
 */
export default () => {
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,

        canDeleteTree() {
            return false
        },

        canMove() {
            return false
        },

        canWriteNode() {
            return false
        },

        getStyle(map, node) {
            return {
                ...BaseNode.getStyle.call(this, map, node),
                backgroundColor: Color.LIGHT_GREY,
            }
        },

        isMounted(map, node) {
            return true
        },

        async onAttachTo(map, node) {
            for (const n of [...map.children(node.parent)]) {
                if (
                    n !== node &&
                    this.type === n.data.bizNodeType
                ) {
                    map.unlinkTree(node)
                    map.insertSiblingAfter(n, node)
                    map.deleteTree(n)

                    map.logger.warn(
                        '已存在相同类型的固定节点，替换原有节点',
                        [node]
                    )
                }
            }

            await BaseNode.onAttachTo.call(this, map, node)
        },
    })
}
