import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaAlgInput.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        async onPull(map, node) {
            await ArCatNode.onPull.call(this, map, node)
            node.isFolded = false
        },
    })
}
