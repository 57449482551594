import metaBizNode from '../metaBizNode.mjs'

const toObject = raw => {
    if ('string' === typeof raw) {
        return JSON.parse(raw)
    }
    else {
        return raw
    }
}

const parseTree = (tree, chain = []) => {
    const bizNodeType = (() => {
        if (tree.data) {
            return tree.data.bizNodeType
        }
        else {
            return tree.bizNodeType
        }
    })()

    const {
        children = [],
        data,
        isFolded = false,
        mapJson,
    } = (() => {
        const {mapProp} = metaBizNode[bizNodeType] ?? {}

        if (tree.data && tree.style) {
            const {
                children,
                data: {[mapProp]: mapJson, ...data},
                isFolded,
                style,
            } = tree

            return {
                children,
                data: {...data, style},
                isFolded,
                mapJson,
            }
        }
        else if (tree.data) {
            const {
                children,
                data: {[mapProp]: mapJson, ...data},
                isFolded,
            } = tree

            return {children, data, isFolded, mapJson}
        }
        else if (tree.style) {
            const {
                [mapProp]: mapJson,
                children,
                isFolded,
                style,
                ...data
            } = tree

            return {
                children,
                data: {...data, style},
                isFolded,
                mapJson,
            }
        }
        else {
            const {
                [mapProp]: mapJson,
                children,
                isFolded,
                ...data
            } = tree

            return {
                children,
                data,
                isFolded,
                mapJson,
            }
        }
    })()

    const node = {data, isFolded}

    const mapChildren = (children) => children.map(
        subTree => parseTree(subTree, [node, ...chain])
    )

    if (0 < children.length || ! mapJson) {
        return {
            children: mapChildren(children),
            ...node,
        }
    }
    else {
        const map = JSON.parse(mapJson)

        if (Array.isArray(map)) {
            return {
                children: mapChildren(map),
                ...node,
            }
        }
        else {
            return {
                children: mapChildren(map.children),
                ...node,
            }
        }
    }
}

export default raw => {
    const mapData = toObject(raw)

    if (! mapData.version) {
        if (mapData.mapModel) {
            const {mapModel, ...data} = mapData
            const {root} = toObject(mapModel)

            return {
                data,
                root: parseTree(root),
            }
        }
        else if (mapData.root) {
            if (mapData.data) {
                const {root, data} = mapData

                return {
                    data,
                    root: parseTree(toObject(root)),
                }
            }
            else {
                const {root, ...data} = mapData

                return {
                    data,
                    root: parseTree(toObject(root)),
                }
            }
        }
        else if (Array.isArray(mapData)) {
            return mapData.map(parseTree)
        }
        else {
            return parseTree(mapData)
        }
    }
    else {
        return mapData
    }
}
