import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaCopr.mjs'
import OprType from '../FOPR/OprType.jsx'
import Model from '../FOPR/ModelFopr.mjs'
import PropertiesPane from '../FOPR/PropertiesPaneFopr.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(CRES|PIC)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'CRES'
        },

        getIcons(map, node) {
            const type2icon = new Map(
                OprType.flat().map(([type, , icon]) => [type, icon])
            )

            const icon = type2icon.get(node.data.oprType)

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.GOLD}),
                shape: 'HorizontalHexagon',
            }
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('FRES' === bizNodeType) {
                map.BizNode.CRES.castFrom(map, child)
            }

            ComponentNode.onAttached.call(this, map, node, child)
        },

        async onCreate(map, node) {
            node.data = {...node.data, oprType: 'GENERAL'}
            await ComponentNode.onCreate.call(this, map, node)
        },

        async onPull(map, node) {
            await ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            let dataKey, oprCat, oprScene, sceneNo

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType} = n.data

                if ('CAT' === bizNodeType) {
                    if (! oprCat) {
                        oprCat = map.BizNode.CAT.getTextRaw(map, n)
                    }
                }
                else if (map.BizNode[bizNodeType].isComment) {
                    continue
                }
                else if ('ROLE' === bizNodeType) {
                    dataKey = 'ucOprList'
                    oprScene = map.BizNode.ROLE.getTextRaw(map, n)
                    sceneNo = String(n.id)
                    break
                }
            }

            if (! dataKey) {
                return
            }

            const d = {
                ...this._getPushData(map, node, {child: []}),
                oprCat,
                oprScene,
                picId: '',
                picName: '',
                sceneNo,
            }

            for (const child of map.children(node)) {
                const {bizNodeType} = child.data

                if ('PIC' === bizNodeType) {
                    const {fileId: picId, text: picName} = child.data

                    if (picId) {
                        Object.assign(d, {picId, picName})
                        break
                    }
                }
            }

            data[dataKey].push(d)
        },
    })
}
