import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonMap from '../components/PropertiesPaneButtonMap.jsx'
import Model from './ModelDm.mjs'

const PropertiesPaneDm = (props) => (
    <ComponentPropertiesPane
        buttons={<ButtonMap />}

        controllers={
            <>
                <Text prop="dmUserCode" />
                <Text prop="dmName" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneDm
