export default (superNode, props) => {
    const descs = Object.fromEntries(
        Object.keys(props).map(
            key => {
                const desc = Reflect.getOwnPropertyDescriptor(props, key)
                const {get, value} = desc

                return [
                    key,

                    {
                        ...(value ? {value, writable: false} : {get}),
                        configurable: false,
                        enumerable: false,
                    },
                ]
            }
        )
    )

    return Object.create(superNode, descs)
}
