export const canShrinkNode = () => () => {
    return function (node) {
        const {bizNodeType} = node.data
        return this.BizNode[bizNodeType].canShrink(this, node)
    }
}

export const shrinkNode = () => () => {
    return function (nodes) {
        for (const node of nodes) {
            const {bizNodeType} = node.data
            this.BizNode[bizNodeType].shrink(this, node)
            this.logger.info('已卸载节点', [node])
        }
    }
}
