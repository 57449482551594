import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaDgf.mjs'
import Model from './ModelDgf.mjs'
import PropertiesPane from './PropertiesPaneDgf.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        attrNodes: {
            bottom: ['ATTR_DF_DESC'],
        },

        castTo(map, node, bizNodeType) {
            ComponentNode.castTo.calll(this, map, node, bizNodeType)

            if ('DF' === bizNodeType) {
                node.data = {
                    ...node.data,
                    isCdgDf: '1',
                    isNull: '1',
                }
            }
        },

        getIcons(map, node) {
            return map.BizNode.DF.getIcons.call(this, map, node)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {borderColor: Color.CYAN})
        },

        getTextSuffix(map, node) {
            const {
                pkid,
                [this.codeProp]: code,
            } = node.data

            if (pkid) {
                return ` ${code}`
            }
            else {
                return ComponentNode.getTextSuffix.call(this, map, node)
            }
        },

        mapUpdateNodeData(map, node, data) {
            const d = {...data}
            const oldData = node.data

            if (
                'defVal' in data ||
                'dfLen' in data ||
                'dfPrecision' in data
            ) {
                const {
                    dataTypeCode,
                    defVal,
                    dfLen,
                    dfPrecision,
                } = {...oldData, ...data}

                if (
                    dfLen &&
                    dfPrecision &&
                    dfLen < dfPrecision
                ) {
                    d.dfPrecision =  dfLen - 1
                }
                else {
                    d.dfPrecision = dfPrecision
                }

                if (defVal) {
                    if (/^(DOUBLE|FLOAT|NUMBER)$/.test(dataTypeCode)) {
                        d.defVal = Number(String(defVal).replace(
                            /^(-?)(\d*)(\.?)(\d*)$/,

                            (match, p1, p2, p3, p4) => {
                                if (dfLen && d.dfPrecision) {
                                    p2 = p2.slice(0, dfLen)

                                    p4 = p4.slice(
                                        0,

                                        Math.min(
                                            dfLen - p2.length,
                                            d.dfPrecision
                                        )
                                    )
                                }
                                else if (dfLen) {
                                    p2 = p2.slice(0, dfLen)
                                    p4 = p4.slice(0, dfLen - p2.length)
                                }
                                else if (d.dfPrecision) {
                                    p4 = p4.slice(0, d.dfPrecision)
                                }

                                return [p1, p2, p3, p4].join('')
                            }
                        ))
                    }
                    else if (dfLen) {
                        d.defVal = defVal.slice(0, dfLen)
                    }
                }
            }

            return d
        },

        onPush(map, node, type, data) {
            if ('CDG' !== type) {
                return
            }

            const pushData = this._getPushData(map, node)
            data.pcdgDfList.push(pushData)
        },
    })
}

