import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    detailUrl: '/ArchMiddlewareDetail',
    detailIdProp: 'middlewareId',
    mapProp: 'map',
    name: '中间件',
    textProp: 'middlewareName',
    type: 'MID',
}
