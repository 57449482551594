import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaMidins.mjs'
import Model from './ModelMidins.mjs'
import PropertiesPane from './PropertiesPaneMidins.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(MIDINS_(DEPENDENCE_BY|DEPLOYED_IN))$/.test(type)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="M"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.DARK_GREEN,
            })
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ComponentNode.getTextPrefix.call(
                this, map, node
            )

            const {middlewareInsEnv} = node.data

            if (middlewareInsEnv) {
                return `${defaultPrefix}【${middlewareInsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        async onCreate(map, node) {
            const childTypes = [
                'MIDINS_DEPLOYED_IN',
                'MIDINS_DEPENDENCE_BY',
            ]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await ComponentNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                MID: 'archMiddlewareInss',
                SSINS: 'archSsInsDependences',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(
                map,
                node,
                {archMiddlewareInsDeploys: []}
            )

            const tree = this.exportTree(map, node, {excludeGrowed: true})

            data[key].push({
                ...pushData,
                map: JSON.stringify(tree.children),

                ...({
                    SSINS: () => {
                        const {prjId, middlewareInsCode, sVer} = node.data

                        return {
                            dependenceType: 'MIDDLEWARE',
                            dependencePrjId: prjId,
                            dependenceSVer: sVer,
                            dependenceCode: middlewareInsCode,
                        }
                    },
                }[type])?.(),
            })
        },
    })
}
