import useUnknown from './nodes/_UNKNOWN/useUnknownNode.jsx'

const modules = import.meta.glob(
    './nodes/*/useNode*.jsx',

    {
        import: 'default',
        eager: true,
    }
)

export default () => {
    const Unknown = useUnknown()

    const BizNode = Object.fromEntries(
        Object.values(modules).map(
            useNode => {
                const Node = useNode()
                return [Node.type, Node]
            }
        )
    )

    return new Proxy(BizNode, {
        get(target, prop) {
            if (target[prop]) {
                return target[prop]
            }
            else {
                return Unknown
            }
        }
    })
}
