import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaSfMain.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|FOPR)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'FOPR'
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('COPR' === bizNodeType) {
                map.BizNode.FOPR.castFrom(map, child)
            }

            CategoryNode.onAttached.call(this, map, node, child)
        },
    })
}
