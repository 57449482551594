import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'

export default function PropertiesControllerProductSn(props) {
    return (
        <Text
            prop="dpSn"
            readOnly
            title="模件编号"
            {...props}
        />
    )
}
