import {useOpenModal} from '@/components/Modal/Modal.jsx'
import SelectMapVerDialog from '@/pages/MainPage/dialogs/SelectMapVerDialog.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import {replaceWithTree} from '../../scripts/map.mjs'

export default () => {
    const openModal = useOpenModal()
    const map = useMapContext()
    const permission = map.usePermission()

    if (
        ! permission.has('write') ||
        '_DES_MAP' === map.data.mapTypeCode
    ) {
        return null
    }

    const onClick = async () => {
        const data = await openModal(
            <SelectMapVerDialog id={map.data.pkid} />
        )

        if (! data) {
            return
        }

        const {root} = parseMap(data.dataMap)

        await map.cmd(
            () => replaceWithTree(map, map.root, root)
        )
    }

    return {
        key: 'import-snapshot',
        label: '导入历史版本',
        onClick,
    }
}
