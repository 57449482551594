import http from '@/biz/http.mjs'

export default {
    create: (args) => http.post('/sharemap', args),

    read: async ({id}) => {
        const {dataMap} = await http.get(`/sharemap/${id}`)
        return dataMap
    },
}
