import {Button} from 'antd'
import http from '@/biz/http.mjs'
import {writeFileToDisk} from '@/script/file.mjs'
import runAsync from '@/script/runAsync.mjs'

const PropertiesPaneButtonExportXml = ({nodes, ...props}) => {

    const ids = nodes
        .filter(({data: {pkid, isClass, vmPack}}) => (
            pkid && '1' === isClass && vmPack
        ))
        .map(({data: {pkid}}) => pkid)

    if (0 === ids.length) {
        return null
    }

    const handleClick = async () => {
        const {blob, fileName} = await runAsync(
            () => http.get('/archvms/xml', {ids}),
            {action: '导出XML'}
        )

        writeFileToDisk(blob, {suggestedName: fileName})
    }

    return (
        <Button
            type="primary"
            onClick={handleClick}
            {...props}
        >导出XML</Button>
    )
}

export default PropertiesPaneButtonExportXml
