import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    cdgDfCode: {
        name: () => '源标准字段',
        type: 'key',
    },

    dataTypeCode: {
        name: () => '数据类型',

        disabled: ({bizNodeType, cdgDfUserCode, isCdgDf, isRefDf}) => (
            '1' === isCdgDf ||
            '1' === isRefDf ||
            ('DF' === bizNodeType && cdgDfUserCode)
        ),

        required: () => true,
        type: 'key',
    },

    defVal: {
        name: () => '默认值',
        hidden: ({dataTypeCode}) => /^(B?LOB|DATE(TIME)?)$/.test(dataTypeCode),
        type: 'string'
    },

    dfDesc: {
        name: () => '字段说明',
        type: 'text',
    },

    dfUserCode: {
        name: () => '字段代码',
        required: () => true,
        type: 'string',
    },

    dfName: {
        name: () => '字段名称',
        required: () => true,
        type: 'string',
    },

    dfAlias: {
        name: () => '字段别名',
        type: 'string',
    },

    dfLen: {
        name: () => '字段长度',

        disabled: ({cdgDfUserCode, dataTypeGrpCode, isCdgDf, isRefDf}) => (
            'BDT' !== dataTypeGrpCode ||
            '1' === isCdgDf ||
            '1' === isRefDf ||
            cdgDfUserCode
        ),

        hidden: ({isLen}) => '1' !== isLen,
        //required: ({isLen}) => '1' === isLen,
        type: 'number',
    },

    dfPrecision: {
        name: () => '字段精度',

        disabled: ({cdgDfUserCode, dataTypeGrpCode, isCdgDf, isRefDf}) => (
            'BDT' !== dataTypeGrpCode ||
            '1' === isCdgDf ||
            '1' === isRefDf ||
            cdgDfUserCode
        ),

        hidden: ({isPrecision}) => '1' !== isPrecision,
        //required: ({isPrecision}) => '1' === isPrecision,
        type: 'number',
    },

    isCdgDf: {
        name: () => '引用标准字段组字段',
        type: 'boolean',
    },

    isBk: {
        name: () => '是否 BK',
        type: 'boolean',
    },

    isNull: {
        name: () => '是否可空',
        disabled: ({isPk}) => '1' === isPk,
        type: 'boolean',
    },

    isPk: {
        name: () => '是否 PK',
        type: 'boolean',
    },
})
