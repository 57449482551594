import {exportTree} from '../../scripts/map.mjs'

export const canDuplicateTree = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canDuplicateTree(node) &&
            this.BizNode[bizNodeType].canDuplicate(this, node)
        )
    }
}

export const duplicateTree = () => () => {
    return async function (nodes) {
        const nodesToSelect = new Set()

        const duplicate = async (trees) => {
            const newNodes = []

            for (const [node, descendants] of trees) {
                duplicate(descendants)
                const tree = exportTree(this, node)
                const cloned = this.importTree(tree)
                this.insertSiblingAfter(node, cloned)
                newNodes.push([node, cloned])
            }

            await Promise.all(
                newNodes.map(async ([src, dest]) => {
                    const {bizNodeType} = dest.data
                    await this.BizNode[bizNodeType].onAttachTo(this, dest)

                    if (! dest.isDeleted) {
                        this.logger.info('已复制分支', [src])
                        nodesToSelect.add(dest)
                    }
                })
            )
        }

        const trees = this.treelize(nodes)
        await duplicate(trees)
        this.selectNodes(nodesToSelect)
    }
}
