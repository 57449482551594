const insertNode = async (map, nodes, insert) => {
    const nodesToSelect = new Set

    await Promise.all(
        [...nodes].map(async n => {
            const _n = map.nodeProxy(n)
            const t = _n.defaultChildType()

            if (! t) {
                return null
            }

            const sibling = map.createNode(t)
            insert(n, sibling)
            await map.BizNode[t].onCreate(map, sibling)

            if (! sibling.isDeleted) {
                map.logger.info('已插入兄弟节点', [sibling])
                nodesToSelect.add(sibling)
            }
        })
    )

    map.selectNodes(nodesToSelect)
}

export const canInsertSibling = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canInsertSibling(node) &&
            this.BizNode[bizNodeType].canDuplicate(this, node)
        )
    }
}

export const insertSiblingAfter = () => () => {
    return function (nodes) {
        return insertNode(this, nodes, this.insertSiblingAfter.bind(this))
    }
}

export const insertSiblingBefore = () => () => {
    return function (nodes) {
        return insertNode(this, nodes, this.insertSiblingBefore.bind(this))
    }
}
