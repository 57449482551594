import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Check from '../../SidePanel/NodePanel/PropertiesControllerCheck.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Number from '../../SidePanel/NodePanel/PropertiesControllerNumber.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import DataType from '../DF/PropertiesControllerDataType.jsx'
import DefVal from '../DF/PropertiesControllerDefVal.jsx'
import DfPrecision from '../DF/PropertiesControllerDfPrecision.jsx'
import RefCdgDf from './PropertiesControllerRefCdgDf.jsx'
import Model from './ModelDgf.mjs'

const PropertiesPaneDgf = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Check prop="isRefDf" />
                <RefCdgDf />
                <Text prop="dfUserCode" />
                <Text prop="dfName" />
                <DataType />

                <Number
                    int
                    min={1}
                    prop="dfLen"
                />

                <DfPrecision />
                <Check prop="isNull" />
                <DefVal />
                <LongText prop="dfDesc" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneDgf
