import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'uvUserCode',
    mapProp: 'demoMap',
    name: '界面视图',
    ownerIdProp: 'uiId',
    ownerTextProp: 'uiName',
    ownerType: 'UI',
    textProp: 'uvName',
    type: 'UV',
}
