import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import superMeta from '../_ATTRIBUTE/metaAttributeNode.mjs'
import useAttributeNode from '../_ATTRIBUTE/useAttributeNode.jsx'
import IconAvatar from '../icons/IconAvatar.jsx'


export default () => {
    const AttributeNode = useAttributeNode()

    return extendNode(AttributeNode, {
        ...superMeta,
        prop: 'bjPos',
        type: 'ATTR_BJ_POS',

        getIcons() {
            return [
                <IconAvatar
                    key="type"
                    color={Color.BLUE}
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...AttributeNode.getStyle.call(this, map, node),
                backgroundColor: '#fff',
                borderColor: 'var(--main-color)',
                custom: {'--main-color': Color.GOLD},
            }
        },
    })
}
