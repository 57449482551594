import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'
import usePreviewUi from './usePreviewUi.mjs'

export default function PropertiesControllerPreview(props) {
    const map = useMapContext()
    const previewUi = usePreviewUi()
    const selectedNodes = map.useSelectedNodes()

    if (1 !== selectedNodes.size) {
        return null
    }

    const handleClick = () => {
        const [node] = selectedNodes
        previewUi(map, node)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            预览
        </Button>
    )
}
