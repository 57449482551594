import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaAlgCatNode.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        defaultChildType(map, node) {
            return this.algNodeType
        },

        onAttached(map, node, child) {
            const {algType} = child.data

            if (algType) {
                map.BizNode[this.algNodeType].castFrom(map, child)
            }

            CategoryNode.onAttached.call(this, map, node, child)
        },
    })
}
