import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaKm.mjs'
import Model from './ModelKm.mjs'
import api from './apiKm.mjs'
import PropertiesPane from './PropertiesPaneKm.jsx'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(AR|CAT|DOC|PIC|URL)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        getStyle(map, node) {
            return {
                ...ProductNode.getStyle.call(this, map, node),
                backgroundColor: Color.LIGHT_GREY,
            }
        },

        onAttached(map, node, child) {
            map.BizNode.AR.onAttached(map, node, child)
        },
    })
}
