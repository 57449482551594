import {Col, Form, Input, Row, Select} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const FormItemCode = (props) => {
    return (
        <Form.Item
            label="制品代码"
            name="dpUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemName = (props) => {
    return (
        <Form.Item
            label="制品名称"
            name="dpName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemTypes = (props) => {
    const map = useMapContext()

    const options = [
        'BD',
        'BDALG',
        'BDBASE',
        'BDRULE',
        'BF',
        'CDT',
        'DT',
        'FC',
        'LSI',
        'SF',
        'UE',
        'UI',
        'UIALG',
        'UIRULE',
        'UV',
    ].map(type => ({
        label: map.BizNode[type].name,
        value: type,
    }))

    return (
        <Form.Item
            label="制品类型"
            name="bizNodeTypes"
            {...props}
        >
            <Select
                allowClear
                mode="multiple"
                options={options}
            />
        </Form.Item>
    )
}

const FormItemStatus = (props) => {
    const options = [
        {
            label: '发布',
            value: 'RLS',
        },

        {
            label: '修订',
            value: 'REVISE',
        },

        {
            label: '草稿',
            value: 'DRAFT',
        },
    ]

    return (
        <Form.Item
            label="制品状态"
            name="stsCodes"
            {...props}
        >
            <Select
                allowClear
                mode="multiple"
                options={options}
            />
        </Form.Item>
    )
}

const FormItemUserModified = (props) => {
    return (
        <Form.Item
            label="更新人"
            name="uptName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

export default function QueryFormSelectProducts(props) {
    const brief = (
        <Row gutter={12}>
            <Col span={8}>
                <FormItemTypes />
            </Col>

            <Col span={4}>
                <FormItemCode />
            </Col>

            <Col span={4}>
                <FormItemName />
            </Col>

            <Col span={4}>
                <FormItemStatus />
            </Col>

            <Col span={4}>
                <FormItemUserModified />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}
