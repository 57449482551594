import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    detailUrl: '/DepVsDetail',
    detailIdProp: 'vsId',
    mapProp: 'map',
    name: '虚拟机',
    textProp: 'vsName',
    type: 'VR',
}
