import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    catProp: 'dfCat',
    codeProp: 'dfUserCode',
    name: '标准字段',
    ownerIdProp: 'cdgId',
    ownerTextProp: 'cdgName',
    ownerType: 'CDG',
    textProp: 'dfName',
    type: 'DGF',
}
