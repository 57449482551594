import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
import meta from './metaDm.mjs'
import Model from './ModelDm.mjs'
import PropertiesPane from './PropertiesPaneDm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(DM)$/.test(type)
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.CYAN}),
                shape: 'SingleBreakangle',
            }
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if (/^[BF]M$/.test(bizNodeType)) {
                this.castFrom(map, child)
            }

            ModuleNode.onAttached.call(this, map, node, child)
        },

        onPush(map, node, type, data) {
            const key = {
                DB: 'archDmList',
                DM: 'child'
            }[type]

            if (key) {
                data[key].push(
                    this._getPushData(map, node, {child: []})
                )
            }
        },
    })
}
