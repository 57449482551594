import {useOpenModal} from '@/components/Modal/Modal.jsx'
import parseMap from '../../scripts/parseMap.mjs'
import SelectDptDialog from '@/pages/MainPage/dialogs/SelectDptDialog.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import isProductMap from '../isProductMap.mjs'

export default () => {
    const openModal = useOpenModal()
    const map = useMapContext()
    const permission = map.usePermission()

    if (
        ! isProductMap(map) ||
        ! permission.has('write')
    ) {
        return null
    }

    const onClick = async () => {
        const tmpl = await openModal(
            <SelectDptDialog
                dpType={map.root.data.bizNodeType}
                sfTypeCode={map.root.data.sfTypeCode}
                setVisible={() => {}}
            />
        )

        if (! tmpl) {
            return
        }

        const {root: {children}} = parseMap(tmpl)

        await map.cmd(() => {
            for (const child of [...map.children(map.root)]) {
                map.deleteTree(child)
            }

            for (const child of children) {
                const childNode = map.importTree(child)
                map.appendChild(map.root, childNode)
            }
        })
    }

    return {
        key: 'import-template',
        label: '导入模板',
        onClick,
    }
}
