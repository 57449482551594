import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelFres.mjs'

const PropertiesPaneFres = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <LongText prop="resTitle" />
            </>
        }

        Model={Model}
        noSn
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneFres
