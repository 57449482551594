import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import TagSelect from '../../SidePanel/NodePanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelKm.mjs'

const PropertiesPaneKm = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Text prop="kmTitle" />
                <TagSelect prop="kmKey" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneKm
