import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeExtraText from './NodeExtraText.jsx'

export default function NodeDeliveryVersionText({node, ...props}) {
    const map = useMapContext()
    const {bizNodeType} = node.data
    const bn = map.BizNode[bizNodeType]
    const rev = bn.getRev(map, node)
    const version = bn.getVersion(map, node)
    const {deliverRev, deliverVer, sVer} = node.data

    if (! (
        deliverRev &&
        deliverVer &&
        null !== rev &&
        version &&
        '_DES_MAP' === map.data.mapTypeCode &&

        (
            deliverVer !== sVer ||
            deliverRev !== rev
        )
    )) {
        return null
    }

    return (
        <NodeExtraText
            map={map}
            node={node}
            text={`←【${deliverVer}.${deliverRev}】`}
            {...props}
        />
    )
}
