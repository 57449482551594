const readOwnerTree = async (map, node) => {
    const {bizNodeType} = node.data
    const {ownerIdProp, ownerType} = map.BizNode[bizNodeType]
    const {[ownerIdProp]: pkid} = node.data

    if (! pkid) {
        throw new Error(`缺少数据: ${ownerIdProp}`)
    }

    return map.BizNode[ownerType].readTree({pkid})
}

export default async (map, targetNode) => {
    const traverse = (node, texts) => {
        const {bizNodeType, pkid} = node.data
        const text = map.BizNode[bizNodeType].getTextRaw(map, node)

        if (pkid === targetNode.data.pkid) {
            return [...texts, encodeURIComponent(text)]
        }
        else {
            for (const child of node.children) {
                const matchedTexts = traverse(
                    child,
                    [...texts, encodeURIComponent(text)]
                )

                if (0 < matchedTexts.length) {
                    return matchedTexts
                }
            }

            return []
        }
    }

    const tree = await readOwnerTree(map, targetNode)
    const nodeTexts = traverse(tree, [])

    if (0 < nodeTexts.length) {
        return `/${nodeTexts.join('/')}/`
    }
    else {
        return ''
    }
}
