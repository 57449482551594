import http from '@/biz/http.mjs'

export default {

    create: (data) => http.post('/dmdtos', data),

    publish: (data) => http.put(
        '/dmdtos/rlsandrevise',
        {...data, stsCode: 'RLS'}
    ),

    pull: ({pkid}) => http.get(`/dmdtos/${pkid}/dps`),
    push: (data) => http.post('/dmdtos/batchmodify', data),
    read: ({pkid}) => http.get(`/dmdtos/${pkid}`),
    readList: (args) => http.get('/dmdtos', args),

    revise: ({pkid}) => http.put(
        '/dmdtos/rlsandrevise',
        {pkid, stsCode: 'REVISE'}
    ),

    update: (data) => http.put(`/dmdtos/${data.pkid}`, data),
    updateMap: (data) => http.put(`/dmdtos/${data.pkid}/map`, data),
}
