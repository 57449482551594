import superMeta from '../_MODULE/metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'bmNo',
    mapIdProp: 'bizMapId',
    mapStsCodeProp: 'bizMapStsCode',
    mapTypeCode: 'BIZ_MAP',
    name: '业务域',
    ownerIdProp: 'baId',
    ownerTextProp: 'baName',
    ownerType: 'BA',
    textProp: 'bmName',
    type: 'BM',
}
