import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    classes: ['module', ...superMeta.classes],
    mapIdProp: '',
    mapName: '',
    mapStsCodeProp: '',
    mapTypeCode: '',
}
