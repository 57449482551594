export const getNodeShapeStyle = () => () => {
    return function (node) {
        const {bizNodeType} = node.data

        const {
            backgroundColor,
            borderColor,
            borderWidth,
            shape,
        } = {
            ...this.BizNode[bizNodeType].getStyle(this, node),
            ...node.data.style,
        }

        return {
            backgroundColor,
            borderColor,
            borderWidth,
            shape,
        }
    }
}
