import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaSs.mjs'
import Model from './ModelSs.mjs'
import PropertiesPane from './PropertiesPaneSs.jsx'
import QueryForm from './QueryFormSelectSs.jsx'
import Table from './TableSelectSs.jsx'
import api from './apiSs.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(FC_FA|SS_SSINS)$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GOLD}
                    letters="S"
                    textColor="#fff"
                />
            ]
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                archVmList: [],
                archSsInss: [],
            })
        },

        getStyle(map, node) {
            const {isUi} = node.data

            return this._getStyle(map, node, {
                borderColor: {
                    0: Color.GREEN_YELLOW,
                    1: Color.LIGHT_BLUE,
                    2: Color.GOLD,
                }[isUi]
            })
        },

        mapPushResult(data) {
            return this._mapPushResult(data, [
                'archVmList',
                'archSsInss',
            ])
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            for (const n of map.walkUpNoComment(node.parent)) {
                if ('DEP_SS' !== n.data.bizNodeType) {
                    break
                }

                for (const n of [...node.children]) {
                    if ('SS_SSINS' === n.data.bizNodeType) {
                        map.deleteNode(n)
                    }
                    else {
                        map.deleteTree(n)
                    }
                }
            }

            return newDepth
        },
    })
}
