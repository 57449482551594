import ContextMenu from '@/components/ContextMenu/ContextMenu.jsx'
import {usePopover} from '@/components/Popover/index.mjs'
import {MapContext, useMapContext} from '@/components/MindMap/index.mjs'
import useMenuItemGrowNode from '../../ToolBar/Map/useMenuItemGrowNode.jsx'
import useMenuItemGrowNodeRecursive from '../../ToolBar/Map/useMenuItemGrowNodeRecursive.jsx'
import useMenuItemMakeProduct from '../../ToolBar/Map/useMenuItemMakeProduct.jsx'
import useMenuItemShrinkNode from '../../ToolBar/Map/useMenuItemShrinkNode.jsx'
import useMenuItemCopy from '../../ToolBar/Edit/useMenuItemCopy.jsx'
import useMenuItemCopyNodeId from '../../ToolBar/Edit/useMenuItemCopyNodeId.jsx'
import useMenuItemCopyNodeText from '../../ToolBar/Edit/useMenuItemCopyNodeText.jsx'
import useMenuItemCopyNodeUrl from '../../ToolBar/Edit/useMenuItemCopyNodeUrl.jsx'
import useMenuItemCopyStyle from '../../ToolBar/Edit/useMenuItemCopyStyle.jsx'
import useMenuItemCut from '../../ToolBar/Edit/useMenuItemCut.jsx'
import useMenuItemDelete from '../../ToolBar/Edit/useMenuItemDelete.jsx'
import useMenuItemDeleteSingleNode from '../../ToolBar/Edit/useMenuItemDeleteSingleNode.jsx'
import useMenuItemDuplicate from '../../ToolBar/Edit/useMenuItemDuplicate.jsx'
import useMenuItemMoveDown from '../../ToolBar/Edit/useMenuItemMoveDown.jsx'
import useMenuItemMoveUp from '../../ToolBar/Edit/useMenuItemMoveUp.jsx'
import useMenuItemPaste from '../../ToolBar/Edit/useMenuItemPaste.jsx'
import useMenuItemPasteAfter from '../../ToolBar/Edit/useMenuItemPasteAfter.jsx'
import useMenuItemPasteStyle from '../../ToolBar/Edit/useMenuItemPasteStyle.jsx'
import useMenuItemResetStyle from '../../ToolBar/Edit/useMenuItemResetStyle.jsx'
import useMenuItemExportNode from '../../ToolBar/Edit/useMenuItemExportNode.jsx'
import useMenuItemFold from '../../ToolBar/Edit/useMenuItemFold.jsx'
import {useMenuItemsInsertNode} from '../../ToolBar/menuButtons/MenuButtonInsertNode.jsx'
import {useMenuItemsInsertProduct} from '../../ToolBar/menuButtons/MenuButtonInsertProduct.jsx'

const useBizNodeMenuItems = () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (0 === selectedNodes.size) {
        return []
    }

    let type = ''

    for (const n of selectedNodes) {
        const {bizNodeType} = n.data

        if (type) {
            if (type !== bizNodeType) {
                return []
            }
        }
        else {
            type = bizNodeType
        }
    }

    return map.BizNode[type].contextMenu(map, selectedNodes)
}

const useSubMenuExportNode = () => {
    const children = [
        useMenuItemExportNode('image'),
        //useMenuItemExportNode('pdf'),
    ].filter(a => a)

    if (0 === children.length) {
        return null
    }

    return {
        children,
        key: 'export-node',
        label: '导出',
    }
}

const useSubMenuInsertNode = (type) => {
    const children = useMenuItemsInsertNode(type)

    if (0 === children.length) {
        return null
    }

    const typeText = {
        common: '通用',
        concept: '模件定义',
    }[type]

    return {
        children,
        key: `insert-${type}`,
        label: `插入${typeText}节点`,
    }
}

const useSubMenuInsertProduct = () => {
    const children = useMenuItemsInsertProduct()

    if (0 === children.length) {
        return null
    }

    return {
        children,
        key: 'insert-product',
        label: '插入模件链接节点',
    }
}

const NodeContextMenu = (props) => {
    const items = [
        ...useBizNodeMenuItems(),
        useMenuItemCopyNodeUrl(),
        useMenuItemCopyNodeId(),
        useMenuItemCopyNodeText(),
        {divider: true},
        useMenuItemCut(),
        useMenuItemCopy(),
        useMenuItemPaste(),
        useMenuItemPasteAfter(),
        useMenuItemDuplicate(),
        useMenuItemDelete(),
        useMenuItemDeleteSingleNode(),
        {divider: true},
        useMenuItemCopyStyle(),
        useMenuItemPasteStyle(),
        useMenuItemResetStyle(),
        {divider: true},
        useMenuItemMoveUp(),
        useMenuItemMoveDown(),
        {divider: true},
        useMenuItemFold(),
        {divider: true},
        useSubMenuInsertNode('common'),
        useSubMenuInsertNode('concept'),
        useSubMenuInsertProduct(),
        {divider: true},
        useMenuItemMakeProduct(),
        useMenuItemGrowNode(),
        useMenuItemGrowNodeRecursive(),
        useMenuItemShrinkNode(),
        {divider: true},
        useSubMenuExportNode(),
    ].filter(a => a)

    return (
        <ContextMenu
            items={items}
            {...props}
        />
    )
}

export const onContextMenu = () => {
    const popover = usePopover()

    return () => async function (node, event) {
        await this.behaviours.onClickNode(node, event)
        const {clientX: x, clientY: y} = event

        const content = (
            <MapContext.Provider value={this}>
                <NodeContextMenu />
            </MapContext.Provider>
        )

        popover.open(content, {placement: 'bottom right', x, y})
    }
}
