import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaVr_.mjs'
import QueryForm from '../VR/QueryFormSelectVr.jsx'
import Table from '../VR/TableSelectVr.jsx'
import api from './apiVr_.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,

        async chooseProduct(map, node) {
            const items = await this._chooseProduct(
                map, node, QueryForm, Table
            )

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'VR'},
            }))
        },
    })
}
