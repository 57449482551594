import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelSs.mjs'
import SsType from './SsType.mjs'
import TerminalType from './TerminalType.mjs'

const PropertiesPaneSs = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Text prop="ssUserCode" />
                <Text prop="ssName" />

                <Select
                    prop="isUi"
                    options={SsType}
                />

                <Select
                    prop="terminalType"
                    options={TerminalType}
                />

                <LongText prop="ssDesc" />
                <Text prop="devLang" />
                <LongText prop="devTechDesc" />
                <LongText prop="softwareDesc" />
                <LongText prop="hardwareDesc" />
                <LongText prop="networkDesc" />
                <Text prop="deployIp" />
                <LongText prop="deployDesc" />
            </>
        }

        Model={Model}
        snSuffix={<Analyse modes={['Reference']} />}
        {...props}
    />
)

export default PropertiesPaneSs
