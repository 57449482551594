import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaSfRule.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        onPush(map, node, type, data) {
            if ('SF' !== type) {
                return
            }

            data.algList.push(this._getPushData(map, node))
        },
    })
}
