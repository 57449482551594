export default () => {
    const extensions = () => ({
        createNode(bizNodeType) {
            return this.importTree({data: {bizNodeType}})
        },

        *walkUpNoComment(node) {
            for (const n of this.walkUp(node)) {
                const {bizNodeType} = n.data
                if (! this.BizNode[bizNodeType].isComment) {
                    yield n
                }
            }
        },
    })

    return {extensions}
}
