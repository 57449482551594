import http from '@/biz/http.mjs'

export default {

    publish: (data) => http.put(
        '/dmcdts/rlsandrevise',
        {...data, stsCode: 'RLS'}
    ),

    push: (data) => http.post('/dmcdts/batchmodify', data),
    pull: ({pkid}) => http.get(`/dmcdts/${pkid}/dps`),
    read: ({pkid}) => http.get(`/dmcdts/${pkid}`),
    readList: (args) => http.get('/dmcdts', args),

    revise: ({pkid}) => http.put(
        '/dmcdts/rlsandrevise',
        {pkid, stsCode: 'REVISE'}
    ),

    update: (data) => http.put(`/dmcdts/${data.pkid}`),
    updateMap: (data) => http.put(`/dmcdts/${data.pkid}/map`, data),
}
