import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'sfNo',
    detailUrl: '/UcDetail',
    mapProp: 'sfMap',
    detailIdProp: 'sfId',
    name: '集成用例',
    textProp: 'sfName',
    type: 'UC',
}
