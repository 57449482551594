import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    catProp: 'dfCat',
    codeProp: 'dfUserCode',
    name: '表字段',
    ownerIdProp: 'dtId',
    ownerTextProp: 'dtName',
    ownerType: 'DT',
    textProp: 'dfName',
    type: 'DF',
}
