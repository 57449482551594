import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeExtraText from './NodeExtraText.jsx'

const cssVersionText = css({
})

const cssVersionTextOutdated = css({
    color: 'red !important',
})

export default function NodeVersionText({node, ...props}) {
    const map = useMapContext()
    const {bizNodeType} = node.data
    const bn = map.BizNode[bizNodeType]
    const rev = bn.getRev(map, node)
    const version = bn.getVersion(map, node)

    if (! version || null === rev) {
        return null
    }

    const csss = [cssVersionText]
    const isOutdated = bn.isOutdated(map, node)

    if (isOutdated) {
        csss.push(cssVersionTextOutdated)
    }

    return (
        <NodeExtraText
            css={csss}
            node={node}
            text={`【${version}】`}
            {...props}
        />
    )
}
