import extendNode from '../extendNode.mjs'
import useCategoryRootNode from '../_CATEGORY_ROOT/useCategoryRootNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootUm.jsx'
import meta from './metaCatRootUm.mjs'

export default () => {
    const CategoryRootNode = useCategoryRootNode()

    return extendNode(CategoryRootNode, {
        ...meta,
        PropertiesPane,

        canMountType(map, node, type) {
            return (
                /^(UI)$/.test(type) ||
                CategoryRootNode.canMountType.call(this, map, node, type)
            )
        },

        defaultChildType(map, node) {
            return 'UI'
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['UI'],
            ]
        },
    })
}
