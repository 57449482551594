import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import PropertiesPane from '../SVRALG/PropertiesPaneSvralg.jsx'
import PropertiesPaneBatch from '../SVRALG/PropertiesPaneBatchSvralg.jsx'
import meta from './metaSvrbase.mjs'

export default () => {
    const AlgNode = useAlgNode()

    return extendNode(AlgNode, {
        ...meta,
        PropertiesPane,
        PropertiesPaneBatch,

        canMountType(map, node, type) {
            return map.BizNode.SVRALG.canMountType.call(this, map, node, type)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
            })
        },

        async onCreate(map, node) {
            await map.BizNode.SVRALG.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            return map.BizNode.SVRALG.onPush.call(this, map, node, type, data)
        },
    })
}
