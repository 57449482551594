import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaVrDeployedIn.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(PHYS)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['PHYS'],
            ]
        },
    })
}
