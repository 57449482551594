import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    name: '系统响应',
    ownerIdProp: 'sfId',
    ownerTextProp: 'sfName',
    ownerType: 'UC',
    textProp: 'resTitle',
    type: 'CRES',
}
