export const canDeleteTree = () => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canDeleteTree(node) &&
            this.BizNode[bizNodeType].canDeleteTree(this, node)
        )
    }
}

export const deleteTree = () => defaultActions => {
    return async function (nodes) {
        const confirmedNodes = []

        for (const node of this.topNodes(nodes)) {
            const {bizNodeType} = node.data

            const isCancelled = await this.BizNode[bizNodeType].beforeDeleteTree(
                this, node
            )

            if (! isCancelled) {
                confirmedNodes.push(node)
            }
        }

        await defaultActions.deleteTree(confirmedNodes)

        await Promise.all(
            confirmedNodes.map(node => {
                const {bizNodeType} = node.data
                return this.BizNode[bizNodeType].onDeleteTree(this, node)
            })
        )
    }
}
