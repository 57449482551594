export const insertBizNode = () => () => {
    return async function (nodes, type) {
        const nodesToSelect = new Set()

        await Promise.all(
            [...nodes].map(async n => {
                const child = this.createNode(type)
                this.appendChild(n, child)
                await this.BizNode[type].onCreate(this, child)

                if (! child.isDeleted) {
                    this.logger.info('已插入子节点', [n])
                    nodesToSelect.add(child)
                }
            })
        )

        this.selectNodes(nodesToSelect)
    }
}
