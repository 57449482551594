import extendNode from '../extendNode.mjs'
import useBaseNode from '../_BASE/useBaseNode.jsx'
import meta from './metaUnknownNode.mjs'

export default () => {
    const BaseNode = useBaseNode()

    return extendNode(BaseNode, {
        ...meta,

        canDuplicate() {
            return false
        },

        canWriteNode() {
            return false
        },

        canWriteTree() {
            return false
        },

        getStyle(map, node) {
            return {
                ...BaseNode.getStyle.call(this, map, node),
                backgroundColor: 'lightyellow',
                borderColor: 'red',
            }
        },

        getText(map, node) {
            const {bizNodeType} = node.data
            return `未知类型: ${bizNodeType}`
        },
    })
}
